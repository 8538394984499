import type { MetadataEntry } from '@local/webviz/dist/types/xyz';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { generateRandomColors, toSuffixUid } from '@local/webviz/dist/xyz';

import { CATEGORY_SEED } from './constants';

export function categoryDataSnapshot(
    attributeId: string,
    lookupTable: MetadataEntry['metadata']['lookup_table'],
) {
    const mappingId = toSuffixUid(attributeId, UID_SUFFIXES.MAPPING);
    const categoryMappingTitlesId = toSuffixUid(attributeId, UID_SUFFIXES.TITLES);
    const categoryMappingColorsId = toSuffixUid(attributeId, UID_SUFFIXES.COLORS);

    const categoryData = lookupTable.map((e: { key: number }) => e.key);
    const categoryTitles = lookupTable.map((e: { value: string }) => e.value);
    const categoryVisibility = new Array(categoryData.length).fill(true);

    const categoryColor = generateRandomColors(
        categoryData.length,
        `${attributeId}_${CATEGORY_SEED}`,
        false,
    );
    return {
        [categoryMappingTitlesId]: {
            array: categoryTitles,
        },
        [categoryMappingColorsId]: {
            array: categoryColor,
        },
        [mappingId]: {
            data_values: categoryData,
            visibility: categoryVisibility,
        },
    };
}
