import {
    GRID_AREAS,
    PLOT_GRID_AREA,
} from '@local/web-design-system/dist/components/DockableContainer/DockableContainer.constants';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            position: 'sticky',
            display: 'grid',
            gridTemplate: GRID_AREAS,
            gridTemplateColumns: '1fr auto',
            gridTemplateRows: 'repeat(3, auto) 1fr',
            pointerEvents: 'none',
            background: 'white',
        },
        plot: {
            background: 'white',
            height: '100%',
            border: '1px solid transparent',
            gridArea: PLOT_GRID_AREA,
            position: 'absolute',
            pointerEvents: 'all',
        },
        dragOver: {
            border: `1px dashed ${theme.palette.primary.main}`,
        },
    }) as const;

export const useStyles = makeStyles()(styles);
