import { CesiumWidget } from '@cesium/engine';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import { useEffect, useRef } from 'react';

export const CesiumSearchPage = () => {
    const cesiumWidgetRef = useRef<CesiumWidget | null>(null);

    setDocumentTitle('Search');

    useEffect(() => {
        if (cesiumWidgetRef.current === null) {
            cesiumWidgetRef.current = new CesiumWidget('cesiumContainer');
        }
    }, []);

    return (
        <div
            id="cesiumContainer"
            style={{ width: '100%', height: '90vh' }}
            automation-id="cesium-map-container"
        />
    );
};
