import { SchemaIcon } from '../SchemaIcon';

interface Props {
    schema: string;
}

export const Icon = ({ schema }: Props) => {
    const IconToRender = SchemaIcon(schema);

    return <IconToRender />;
};
