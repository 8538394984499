import { fileClient as api } from './fileClient';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        deleteFileById: build.mutation<DeleteFileByIdApiResponse, DeleteFileByIdApiArg>({
            query: (queryArg) => ({
                url: `/file/v2/orgs/${queryArg.organisationId}/workspaces/${queryArg.workspaceId}/files/${queryArg.fileId}`,
                method: 'DELETE',
            }),
        }),
        getFileById: build.query<GetFileByIdApiResponse, GetFileByIdApiArg>({
            query: (queryArg) => ({
                url: `/file/v2/orgs/${queryArg.organisationId}/workspaces/${queryArg.workspaceId}/files/${queryArg.fileId}`,
                params: {
                    version_id: queryArg.versionId,
                    include_versions: queryArg.includeVersions,
                },
            }),
        }),
        updateFileById: build.mutation<UpdateFileByIdApiResponse, UpdateFileByIdApiArg>({
            query: (queryArg) => ({
                url: `/file/v2/orgs/${queryArg.organisationId}/workspaces/${queryArg.workspaceId}/files/${queryArg.fileId}`,
                method: 'PUT',
                params: { version_id: queryArg.versionId },
            }),
        }),
        deleteFileByPath: build.mutation<DeleteFileByPathApiResponse, DeleteFileByPathApiArg>({
            query: (queryArg) => ({
                url: `/file/v2/orgs/${queryArg.organisationId}/workspaces/${queryArg.workspaceId}/files/path/${queryArg.filePath}`,
                method: 'DELETE',
            }),
        }),
        getFileByPath: build.query<GetFileByPathApiResponse, GetFileByPathApiArg>({
            query: (queryArg) => ({
                url: `/file/v2/orgs/${queryArg.organisationId}/workspaces/${queryArg.workspaceId}/files/path/${queryArg.filePath}`,
                params: {
                    version_id: queryArg.versionId,
                    include_versions: queryArg.includeVersions,
                },
            }),
        }),
        upsertFileByPath: build.mutation<UpsertFileByPathApiResponse, UpsertFileByPathApiArg>({
            query: (queryArg) => ({
                url: `/file/v2/orgs/${queryArg.organisationId}/workspaces/${queryArg.workspaceId}/files/path/${queryArg.filePath}`,
                method: 'PUT',
                params: { version_id: queryArg.versionId },
            }),
        }),
        listFiles: build.query<ListFilesApiResponse, ListFilesApiArg>({
            query: (queryArg) => ({
                url: `/file/v2/orgs/${queryArg.organisationId}/workspaces/${queryArg.workspaceId}/files`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    author: queryArg.author,
                    file_name: queryArg.fileName,
                    created_at: queryArg.createdAt,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as fileClientEndpoint };
export type DeleteFileByIdApiResponse = /** status 204 The file has been deleted. */ void;
export type DeleteFileByIdApiArg = {
    fileId: string;
    /** The organisation id allocated to the customer. */
    organisationId: string;
    /** The workspace id allocated to the customer. */
    workspaceId: string;
};
export type GetFileByIdApiResponse =
    /** status 200 An object containing file metadata and at least one Azure Blob Storage file download link, optionally a link for each file version. */ DownloadFileResponse;
export type GetFileByIdApiArg = {
    /** UUID of a file. */
    fileId: string;
    /** The organisation id allocated to the customer. */
    organisationId: string;
    /** The workspace id allocated to the customer. */
    workspaceId: string;
    /** Optional version id value for the desired file version. By default, the response will return the _latest_ version. */
    versionId?: string | null;
    /** Optional inclusion of the `DownloadFile.versions`. By default, the response will return `versions` as `null`. */
    includeVersions?: boolean;
};
export type UpdateFileByIdApiResponse =
    /** status 200 An object containing a pre-signed Azure Blob Storage upload link to use to submit the file content. */ UploadFileResponse;
export type UpdateFileByIdApiArg = {
    fileId: string;
    /** The organisation id allocated to the customer. */
    organisationId: string;
    /** The workspace id allocated to the customer. */
    workspaceId: string;
    /** (Optional) version id to fetch a link for. */
    versionId?: string | null;
};
export type DeleteFileByPathApiResponse = /** status 204 The file has been deleted. */ void;
export type DeleteFileByPathApiArg = {
    /** Path to the file. */
    filePath: any;
    /** The organisation id allocated to the customer. */
    organisationId: string;
    /** The workspace id allocated to the customer. */
    workspaceId: string;
};
export type GetFileByPathApiResponse =
    /** status 200 An object containing file metadata and at least one Azure Blob Storage file download link, optionally a link for each file version. */ DownloadFileResponse;
export type GetFileByPathApiArg = {
    /** Path to the file. */
    filePath: any;
    /** The organisation id allocated to the customer. */
    organisationId: string;
    /** The workspace id allocated to the customer. */
    workspaceId: string;
    /** Optional version id value for the desired file version. By default, the response will return the _latest_ version. */
    versionId?: string | null;
    /** Optional inclusion of the `DownloadFile.versions`. By default, the response will return `versions` as `null`. */
    includeVersions?: boolean;
};
export type UpsertFileByPathApiResponse =
    /** status 200 An object containing a pre-signed Azure Blob Storage upload link to use to submit the file content. */ UploadFileResponse;
export type UpsertFileByPathApiArg = {
    /** Path to the file. */
    filePath: any;
    /** The organisation id allocated to the customer. */
    organisationId: string;
    /** The workspace id allocated to the customer. */
    workspaceId: string;
    /** (Optional) version id to fetch a link for. */
    versionId?: string | null;
};
export type ListFilesApiResponse = /** status 200 A list of files */ ListFilesResponse;
export type ListFilesApiArg = {
    /** The organisation id allocated to the customer. */
    organisationId: string;
    /** The workspace id allocated to the customer. */
    workspaceId: string;
    /** The number of results to return. Defaults to 5000 */
    limit?: number;
    /** The number of results to skip. Defaults to 0 */
    offset?: number;
    /** The profile uuid of the user to filter on. */
    author?: string | null;
    /** The name of the file to filter on. Will perform a case sensitive partial match, so the query `file_name=gold` will match a file with the name `allgoldcolumns.csv */
    fileName?: string | null;
    /** A date or dates (max 2) to filter files by. Dates may contain operator prefixes, in the form '<operator>:<datetime>. The following operators are available ('lt'=less than, 'lte'=less than or equal to, 'gt'=greater than, 'gte'=greater than or equal to).If you omit the operator, then it is assumed the operator is 'equal to'. In this case you may only supply one date. The dates must also be in a valid ISO 8601 format.Dates may include a UTC offset. If the offset is ommitted, the timezone is assumed to be UTC. */
    createdAt?: string[] | null;
};
export type BadRequestError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type MissingTokenError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type InvalidTokenError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type EvoRequestValidationError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type ApiError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type MetadataStoreError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type BadGateway = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type UserInfo = {
    email?: string | null;
    id: string;
    name?: string | null;
};
export type FileVersionResponse = {
    created_at: string;
    created_by: UserInfo | null;
    file_id: string;
    link: string;
    name: string;
    path: string;
    size: number;
    version_id: string;
};
export type DownloadFileResponse = {
    created_at: string;
    created_by?: UserInfo | null;
    download: string;
    etag: string;
    file_id: string;
    name: string;
    path: string;
    self: string;
    size: number;
    version_id: string;
    versions?: FileVersionResponse[] | null;
};
export type FileNotFoundError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type FileDeletedError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type UploadFileResponse = {
    file_id: string;
    upload: string;
    version_id: string;
};
export type InvalidPathError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type FileUploadCompleteError = {
    detail?: string | null;
    status?: number;
    title?: string;
    type?: string;
};
export type ListFileLinks = {
    self: string;
};
export type ListFile = {
    created_at: string;
    created_by: UserInfo | null;
    etag: string;
    file_id: string;
    links: ListFileLinks;
    name: string;
    path: string;
    size: number;
    version_id: string;
};
export type ListFilesResponseLinks = {
    next: string | null;
    prev: string | null;
    self: string;
};
export type ListFilesResponse = {
    count: number;
    files: ListFile[];
    limit: number;
    links: ListFilesResponseLinks;
    offset: number;
    total: number;
};
export const {
    useDeleteFileByIdMutation,
    useGetFileByIdQuery,
    useLazyGetFileByIdQuery,
    useUpdateFileByIdMutation,
    useDeleteFileByPathMutation,
    useGetFileByPathQuery,
    useLazyGetFileByPathQuery,
    useUpsertFileByPathMutation,
    useListFilesQuery,
    useLazyListFilesQuery,
} = injectedRtkApi;
