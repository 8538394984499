import { EllipsisMenu } from '@local/web-design-system/dist/components/EllipsisMenu';

import { useScenePanel } from 'src/visualization/context/hooks/useScenePanel';

import { REMOVE_FROM_SCENE, ZOOM_TO_VIEW } from './SceneListItemMenu.constants';
import type { SceneListItemMenuProps } from './SceneListItemMenu.types';

export function SceneListItemMenu({ objectId, viewId, onClose }: SceneListItemMenuProps) {
    const {
        isZoomToViewActive,
        onZoomToView: handleZoomToView,
        onRemove: handleRemoveItem,
    } = useScenePanel(objectId, viewId);

    const menuOptions = [
        {
            key: 'remove-from-scene',
            ItemComponent: () => REMOVE_FROM_SCENE,
            action: handleRemoveItem,
        },
    ];
    if (isZoomToViewActive) {
        menuOptions.push({
            key: 'zoom-to-view',
            ItemComponent: () => ZOOM_TO_VIEW,
            action: handleZoomToView,
        });
    }

    return (
        <EllipsisMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            menuOptions={menuOptions}
            onClose={onClose}
        />
    );
}
