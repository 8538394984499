import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    footer: {
        backgroundColor: 'black',
        fontSize: '12px',
        height: '80px',
        paddingTop: theme.spacing(1),
    },
    link: {
        padding: '18px 10px 0',
        textDecoration: 'none',
        display: 'block',
        '&:hover': {
            color: '#6f9dba',
            textDecoration: 'none',
            '& .comingSoon': {
                color: '#6f9dba',
            },
        },
    },
    socialsIconlink: {
        display: 'none',
        '@media (min-width: 560px)': {
            padding: '12px 10px 0',
            textDecoration: 'none',
            display: 'block',
            '&:hover svg path': {
                fill: '#6f9dba',
            },
        },
    },
    socialsIconsBlock: {
        marginTop: '-3px',
    },
    contactUs: {
        whiteSpace: 'nowrap',
    },
    logo: {
        paddingTop: '9px',
        width: '32px',
    },
    comingSoon: {
        display: 'block',
        color: theme.palette.grey[500],
        textTransform: 'uppercase',
        fontSize: '8px',
    },
    copywrightText: {
        display: 'none',
        '@media (min-width: 1090px)': {
            display: 'inherit',
        },
    },
    seequentLink: {
        display: 'none',
        '@media (min-width: 800px)': {
            display: 'inherit',
        },
    },
    verticalSpacer: {
        margin: theme.spacing(2),
        borderLeft: `1px solid ${theme.palette.grey[500]}`,
    },
    verticalSpacerHide: {
        display: 'none',
        '@media (min-width: 560px)': {
            display: 'flex',
        },
    },
    gridRootWidth: {
        width: 'fit-content !important',
    },
    evoText1: {
        fontSize: '11px',
        paddingTop: '17px',
        paddingLeft: '4px',
    },
    evoText2: {
        fontWeight: '600',
        fontSize: '11px',
        paddingTop: '17px',
        paddingLeft: '3px',
    },
    evoTextShared: {
        display: 'none',
        '@media (min-width: 500px)': {
            display: 'flex',
        },
    },
}));
