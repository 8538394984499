import { common } from '@local/web-design-system/dist/styles/common';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    uploadMoreButton: {
        border: `1px solid ${theme.palette.grey[200]}`,
        borderTop: 'none',
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    uploadingHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontSize: '17px',
        lineHeight: '20px',
        height: '56px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '360px',
        marginLeft: theme.spacing(1),
    },
    uploadContent: {
        maxHeight: 'calc(71vh - 56px)', // 71vh is max height of table next door, 56px is height of header
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    notificationContainer: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.grey[200]}`,
        borderTop: 'none',
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'column',
    },
    uploadList: {
        ...common(theme).thinScrollBar,
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'start',
        border: `1px solid ${theme.palette.grey[200]}`,
        borderTop: 'none',
    },
    progressContainer: {
        display: 'flex',
        padding: theme.spacing(2),
        gap: theme.spacing(3),
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        width: '100%',

        '&:last-child': {
            borderBottom: 0,
        },
    },
    linearProgressContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
    },
    closeIconButton: {
        color: 'white',
    },
    closeIcon: {
        height: theme.spacing(2),
        padding: '5px',
        cursor: 'pointer',
    },
    textContainer: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100%',
        flexBasis: '100%',
        flexGrow: 0,
    },
    uploadStatus: {
        color: theme.palette.grey[500],
        textTransform: 'capitalize',
    },
    fileNameContainer: {
        flexBasis: 0,
        flexGrow: 1,
        overflow: 'hidden',
        maxWidth: '100%',
    },
    fileName: {
        lineHeight: '18px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    linearProgress: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));
