import Grid from '@mui/material/Grid/Grid';

import { CloseIcon } from '../../../assets/CloseIcon';
import { useStyles } from './EvoDialog.styles';

export interface DialogProps {
    onClose: () => void;
    learnMore: any;
    productLogo: any;
    callToAction: string;
}

export const EvoDialog = ({ callToAction, learnMore, onClose, productLogo }: DialogProps) => {
    const { classes } = useStyles();

    return (
        <Grid className={classes.dialog}>
            <div className={classes.header}>
                <button type="button" className={classes.close} onClick={onClose}>
                    <CloseIcon />
                </button>
                <div className={classes.logo}>{productLogo}</div>
            </div>
            <div className={classes.content}>
                <div className={classes.callToAction}>{callToAction}</div>

                {learnMore}
            </div>
        </Grid>
    );
};
