import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid/Grid';
import Link from '@mui/material/Link';

import { ProductScreenshot } from 'src/pages/landingPage/dialogs/learnMore/productScreenshots/ProductScreenshot';

import tick from '../../../../assets/tick.svg';
import { CLOSE } from '../../../../strings';
import { StatusType } from '../../cardList/Card.types';
import { useStyles } from './LearnMore.styles';

export interface LearnMoreProps {
    onClose: () => void;
    description: string;
    features: string[];
    productName: string;
    status: StatusType;
    cta: {
        label: string;
        link: string;
    };
}

export const LearnMore = ({
    onClose,
    description,
    features,
    productName,
    status,
    cta,
}: LearnMoreProps) => {
    const { classes } = useStyles();

    return (
        <>
            <div className={classes.description}>{description}</div>
            <div className={classes.listContainer}>
                {features.map((feature) => (
                    <Grid key={feature} className={classes.listItem}>
                        <img src={tick} className={classes.tick} alt="tick" />
                        <span> {feature} </span>
                    </Grid>
                ))}
            </div>
            {status === 'active' && (
                <span className={classes.findOutMore}>
                    Find out more on{' '}
                    <Link
                        href="https://seequent.com/"
                        target="_blank"
                        color="inherit"
                        rel="noopener"
                    >
                        Seequent.com
                    </Link>
                </span>
            )}
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                className={classes.images}
            >
                <ProductScreenshot productName={`${productName}1.png`} className={classes.image} />
                <ProductScreenshot productName={`${productName}2.png`} className={classes.image} />
            </Grid>
            <Grid container direction="row" justifyContent="end" className={classes.footer}>
                <Grid item className={classes.closeButton} display="flex">
                    <Button variant="text" onClick={onClose}>
                        {CLOSE}
                    </Button>
                </Grid>
                <Button
                    size="large"
                    variant="contained"
                    href={cta.link}
                    target="_blank"
                    className={classes.requestDemoButton}
                >
                    {cta.label}
                </Button>
            </Grid>
        </>
    );
};
