import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    appBar: {
        height: theme.spacing(8),
        backgroundColor: '#1D1F23',
        boxShadow: '0 2px 7px 0 rgba(30, 32, 36, 0.13)',
    },
    logo: {
        margin: '19px 0 20px 21px',
        height: theme.spacing(8),
    },
    loginButton: {
        backgroundColor: '#2AB6FF',
        borderRadius: '4px !important',
        fontSize: '14px !important',
        lineHeight: '21px',
        color: 'white',
        borderColor: '#4CA0E7',
        height: '42px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            borderColor: '#4CA0E7',
        },
    },
}));
