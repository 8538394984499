import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    clearAllButton: {
        marginLeft: theme.spacing(3),
        borderLeft: `1px solid ${theme.palette.grey['200']}`,
    },
    optionsContainer: {
        padding: theme.spacing(1),
    },
}));
