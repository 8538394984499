import {
    FieldDefinition,
    ListCellComponentProps,
    ListItem,
} from '@local/web-design-system/dist/components/GenericListing';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';

const LoadingCell = () => <Skeleton variant="text" />;
const LoadingActionCell = () => <Skeleton variant="circle" height={32} width={32} />;

export const LoadingNameCell = ({ item }: ListCellComponentProps) => (
    <div style={{ paddingLeft: `${(item.depth + 1) * 30}px` }}>
        <Skeleton variant="text" />
    </div>
);

const loadingRowDefinition: FieldDefinition[] = [
    {
        key: 'loading1',
        label: 'Name',
        component: LoadingNameCell,
    } as FieldDefinition<string>,
    {
        key: 'loading2',
        label: 'Name',
        component: LoadingCell,
    } as FieldDefinition<string>,
    {
        key: 'loading3',
        label: 'Name',
        component: LoadingCell,
    } as FieldDefinition<string>,
    {
        key: 'loading4',
        label: 'Name',
        component: LoadingCell,
    } as FieldDefinition<string>,
    {
        key: 'loading5',
        label: '',
        component: LoadingActionCell,
    } as FieldDefinition<string>,
];

export function LoadingRow() {
    return <ListItem item={{}} fields={loadingRowDefinition} />;
}
