import { common } from '@local/web-design-system/src/styles/common';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    hoveringTable: {
        ...common(theme).hoveringTable,
        position: 'relative',
        overflow: 'auto',
        padding: theme.spacing(3),
        minHeight: '78vh',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
