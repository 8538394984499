import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    objectActionGrid: {
        gap: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: '216px',
        minWidth: '140px',
        width: '12vw',
    },
    objectActionText: {
        fontSize: '13px',
    },
    divider: {
        marginTop: '0px !important',
        marginBottom: '0px !important',
    },
}));

export default useStyles;
