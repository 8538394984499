import { FieldDefinition } from '@local/web-design-system/dist/components/GenericListing';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import * as React from 'react';

import { TableDragAndDrop } from 'src/components/dragAndDrop/TableDragAndDrop';

import { useStyles } from './TableHeader.styles';

export interface TableHeaderProps {
    fields: FieldDefinition[];
    hasCheckbox?: boolean;
    hasActions?: boolean;
    hasDragAndDrop?: boolean;
}

export const TableHeader = ({
    fields,
    hasActions = false,
    hasCheckbox = false,
    hasDragAndDrop = false,
}: TableHeaderProps) => {
    const { classes } = useStyles();

    const tableCellNodes: React.ReactNode[] = [];
    fields.forEach((fieldDefinition: FieldDefinition) => {
        const { key, label, styles, tableHeaderClassName } = fieldDefinition;

        tableCellNodes.push(
            <TableCell
                key={key}
                className={classnames(classes.headerCell, tableHeaderClassName)}
                style={styles}
            >
                <Typography
                    className={classes.headerText}
                    style={{ fontSize: '14px', fontWeight: '500' }}
                >
                    {label}
                </Typography>
            </TableCell>,
        );
    });

    return (
        <>
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    {hasCheckbox ? <TableCell className={classes.headerCell} /> : null}
                    {tableCellNodes}
                    {hasActions ? <TableCell className={classes.headerCell} /> : null}
                </TableRow>
            </TableHead>
            {hasDragAndDrop ? <TableDragAndDrop colSpan={6} /> : null}
        </>
    );
};
