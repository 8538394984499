import Grid from '@mui/material/Grid/Grid';

import { products } from '../../../productList';
import { CARD_LIST_DESCRIPTION, CARD_LIST_TITLE } from '../../../strings';
import { Card } from './Card';
import { StatusType } from './Card.types';
import { useStyles } from './CardList.styles';

export const CardList = () => {
    const { classes } = useStyles();

    return (
        <Grid container className={classes.cardList}>
            <Grid item container direction="column" alignItems="center">
                <Grid item>
                    <h3 className={classes.header}>{CARD_LIST_TITLE}</h3>
                </Grid>
                <Grid item>
                    <span className={classes.description}>{CARD_LIST_DESCRIPTION}</span>
                </Grid>
            </Grid>
            <Grid item container className={classes.cards}>
                {products.map((card) => (
                    <Card
                        key={card.name}
                        productName={card.name}
                        logoName={card.logoName}
                        tags={card.tags}
                        isIntegration={card.isIntegration}
                        loginLink={card.loginLink}
                        status={card.status as StatusType}
                        descriptionPrimary={card.descriptionPrimary}
                        descriptionSecondary={card.descriptionSecondary}
                        learnMore={card.learnMore}
                        cta={card.cta}
                    />
                ))}
            </Grid>
        </Grid>
    );
};
