import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ variant?: string }>()((theme: Theme, { variant }) => ({
    openInButton: {
        color: theme.palette.common.white,
        backgroundColor:
            variant === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main,
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&:hover': {
            backgroundColor:
                variant === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.light,
        },
        '&:active': {
            backgroundColor:
                variant === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
        },
        marginTop: theme.spacing(2),
        maxWidth: theme.spacing(40),
        display: 'flex',
        justifyContent: 'space-between',
        height: '48px',
        paddingLeft: `${theme.spacing(4.2)} !important`,
    },
    openInButtonDownIcon: {
        fontSize: 'small',
    },
    menu: {
        '& .MuiPaper-root': {
            backgroundColor:
                variant === 'dark' ? theme.palette.secondary.light : theme.palette.common.white,
        },
    },
    listItem: {
        fontSize: '13px',
        paddingBottom: '6px',
    },
    buttonText: {
        fontSize: '13px',
    },
    menuItem: {
        paddingTop: theme.spacing(1.25),
        paddingLeft: theme.spacing(3.35),
        height: '100%',
        display: 'block',
        color: variant === 'dark' ? theme.palette.common.white : theme.palette.primary.main,
        '&:hover': {
            color: `${theme.palette.primary.contrastText}`,
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
        '&:active': {
            color: theme.palette.primary.contrastText,
            backgroundColor: `${theme.palette.grey[800]} !important`,
        },
    },
}));
