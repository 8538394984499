import React, { createContext, useContext, useMemo, useState } from 'react';

interface Context {
    hoveredObject: string;
    setHoveredObject: (objectID: string) => void;
}

export const SearchInteractionContext = createContext<Context>({
    hoveredObject: '',
    setHoveredObject: () => {},
});

export const useSearchInteractionContext = () => useContext(SearchInteractionContext);

interface Props {
    children: React.ReactNode;
}

export const SearchInteractionProvider = ({ children }: Props) => {
    const [hoveredObject, setHoveredObject] = useState('');

    return (
        <SearchInteractionContext.Provider
            value={useMemo(
                () => ({
                    hoveredObject,
                    setHoveredObject,
                }),
                [hoveredObject, setHoveredObject],
            )}
        >
            {children}
        </SearchInteractionContext.Provider>
    );
};
