import { setLoggingTag } from '@local/error-logging/dist/src/errorLogging';
import {
    LOGIN_ERROR_ROUTE,
    getCurrentEvoInstance,
    getAvailableEvoInstances,
    setCurrentEvoInstance,
} from '@local/login';
import { LOGIN_ORG_ROUTE } from '@local/login/dist/constants';
import {
    EvoInstance,
    getLastUsedHub,
} from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { Location, Navigate, Params, useLocation, useParams } from 'react-router-dom';

import { DEFAULT_ROUTE } from '../../urls';

export interface OrgRouteGuardProps {
    children: JSX.Element;
}

/**
 * Route guard that ensures that the users org from the url match the selected org
 * that they are currently signed into.
 * The url is the source of truth and we only fallback to the org stored in local/session
 * storage if the url doesn't contain an org uuid. Our L7 redirects should ensure that we
 * always receive correctly formatted urls. We should only get an invalid url if the user has typed
 * it themselves, and are already logged in.
 */
export const OrgRouteGuard = ({ children }: OrgRouteGuardProps) => {
    const params = useParams();
    const location = useLocation();

    // Check that we have access to at least one entitlement
    const availableEvoEntitlements = getAvailableEvoInstances();
    if (!availableEvoEntitlements || !availableEvoEntitlements.length) {
        return <Navigate to={`../${LOGIN_ERROR_ROUTE}?error=no_access`} replace />;
    }

    let { orgUuid } = params;
    const { hubCode } = params;

    const currentEvoInstance = getCurrentEvoInstance();
    const orgFromStorage = currentEvoInstance?.org?.id ?? '';

    // if no orgUuid in URL
    if (!orgUuid || !isUuid(orgUuid)) {
        if (!orgFromStorage) {
            // The user doesn't have an org persisted in session/local storage or in the URL
            // Make them pick one
            return <Navigate to={LOGIN_ORG_ROUTE} />;
        }

        // While we could continue with the org from storage, lets redirect them to a url with the correct
        // orgUuid first.
        orgUuid = orgFromStorage;

        const redirectUrl = buildRedirectUrl(location, orgUuid);
        return <Navigate to={redirectUrl} replace />;
    }

    // Check that the user has access to the org. This isn't an authoritative check, which will be covered
    // when the license token is retrieved, but is a good check for whether we need to return them to the selector page
    const lastUsedHub = getLastUsedHub();
    const lastUsedEntitlement = availableEvoEntitlements.find(
        ({ org, hub }: EvoInstance) => org?.id === orgUuid && hub?.code === lastUsedHub?.hub.code,
    );

    const organization =
        lastUsedEntitlement ??
        availableEvoEntitlements.find(({ org, hub }: EvoInstance) =>
            hubCode ? org?.id === orgUuid && hub?.code === hubCode : org?.id === orgUuid,
        );
    if (!organization) {
        return <Navigate to={LOGIN_ORG_ROUTE} />;
    }

    // if org in Url does not match storage, update it
    if (orgUuid !== orgFromStorage) {
        setCurrentEvoInstance({ org: organization.org, hub: organization.hub });
    }

    setLoggingTag('Org', organization.org.display_name);
    setLoggingTag('Hub', organization.hub.url);

    return children;
};

function isUuid(testString: string) {
    return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        testString,
    );
}

function buildRedirectUrl(location: Location, selectedOrgUuid: string) {
    let { pathname } = location;

    // if url is empty, send to default route
    if ([`/${selectedOrgUuid}/`, '/', ''].includes(location.pathname)) {
        pathname = DEFAULT_ROUTE;
    }

    // put orgUuid in front of path that lacks it
    if (pathname.startsWith('/')) {
        pathname = pathname.substring(1);
    }
    return `/${selectedOrgUuid}/${pathname}${location.search}`;
}
// These are defined here to abstract away the undefined-ness from consuming functions
export function getOrgUuidFromParams({ orgUuid }: Params): string {
    // Because of the OrgRouteGuard, this should never actually be undefined
    if (orgUuid === undefined) {
        throw Error('Org Uuid is undefined');
    }
    return orgUuid ?? '';
}

export function getSelectedWorkspaceFromParams({ workspaceUuid }: Params): string {
    // This should only be called from pages that are children of the workspaceUuid route
    // therefore it should never be undefined
    if (workspaceUuid === undefined) {
        throw Error('workspace Uuid is undefined. Check you are calling this from a valid route');
    }
    return workspaceUuid ?? '';
}

export function getHubUrlForCurrentOrg() {
    const { hub } = getCurrentEvoInstance();
    if (!hub) {
        throw Error('Could not retrieve hub.');
    }
    return hub.url;
}
export function getHubForCurrentOrg() {
    const { hub } = getCurrentEvoInstance();
    if (!hub) {
        throw Error('Could not retrieve hub.');
    }
    return hub;
}

export function getHubCodeFromParams({ hubCode }: Params): string {
    // This should only be called from pages that are children of the hubCode route
    if (hubCode === undefined) {
        throw Error('hub code is undefined');
    }
    return hubCode ?? '';
}
