import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';

import { useStyles } from './ScaleBar.styles';

const FEET_IN_MILE = 5280;
const BAR_WIDTH_SPLIT = 2;
const DEFAULT_BAR_WIDTH = 300;
const FEET_IN_METRE = 3.2808399;

/**
 * Taken from leaflet's scale control class.
 * Used to return a rounded number for the scale bar.
 * https://github.com/Leaflet/Leaflet/blob/ed5778c319a0b5466e6f52b49004539ff8fff774/src/control/Control.Scale.js#L117
 */
function getRoundNum(num: number) {
    const pow10 = 10 ** (`${Math.floor(num)}`.length - 1);
    let d = num / pow10;

    // eslint-disable-next-line no-nested-ternary
    d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;

    return pow10 * d;
}

const range = (num: number) => [...Array(num).keys()];

interface ScaleBarProps {
    barWidth?: number;
}

export function ScaleBar({ barWidth = DEFAULT_BAR_WIDTH }: ScaleBarProps) {
    const map = useMap();
    const [metricBarWidth, setMetricBarWidth] = useState(0);
    const [metricDistance, setMetricDistance] = useState(0);
    const [metricUnit, setMetricUnit] = useState('km');
    const [imperialBarWidth, setImperialBarWidth] = useState(0);
    const [imperialDistance, setImperialDistance] = useState(0);
    const [imperialUnit, setImperialUnit] = useState('mi');
    const { classes } = useStyles({ metricBarWidth, imperialBarWidth, fullBarWidth: barWidth });

    const updateMetric = (maxMeters: number) => {
        const metres = getRoundNum(maxMeters);
        const ratio = metres / maxMeters;
        const width = Math.round((barWidth / BAR_WIDTH_SPLIT) * ratio);
        setMetricBarWidth(width);
        setMetricUnit(metres < 1000 ? 'm' : 'km');
        setMetricDistance(metres < 1000 ? metres : metres / 1000);
    };

    const updateImperial = (maxMeters: number) => {
        const maxFeet = maxMeters * FEET_IN_METRE;
        let maxMiles;
        let miles;
        let feet;

        if (maxFeet > FEET_IN_MILE) {
            maxMiles = maxFeet / FEET_IN_MILE;
            miles = getRoundNum(maxMiles);
            const ratio = miles / maxMiles;
            const width = Math.round((barWidth / BAR_WIDTH_SPLIT) * ratio);
            setImperialBarWidth(width);
            setImperialDistance(miles);
            setImperialUnit('mi');
        } else {
            feet = getRoundNum(maxFeet);
            const ratio = feet / maxFeet;
            const width = Math.round((barWidth / BAR_WIDTH_SPLIT) * ratio);
            setImperialBarWidth(width);
            setImperialDistance(feet);
            setImperialUnit('ft');
        }
    };

    const updateScale = () => {
        if (map) {
            const y = map.getSize().y / 2;
            const maxMeters = map.distance(
                map.containerPointToLatLng([0, y]),
                map.containerPointToLatLng([barWidth / BAR_WIDTH_SPLIT, y]),
            );
            updateMetric(maxMeters);
            updateImperial(maxMeters);
        }
    };

    useMapEvent('move', updateScale);
    useEffect(() => updateScale(), [map]);

    const metricCount = metricBarWidth ? Math.floor(barWidth / metricBarWidth) : 0;
    const imperialCount = imperialBarWidth ? Math.floor(barWidth / imperialBarWidth) : 0;

    return (
        <Grid container direction="column" className={classes.base}>
            <Grid item container className={classes.textContainer}>
                {imperialCount &&
                    range(imperialCount + 1).map((i) => (
                        <Typography
                            key={i}
                            className={classNames(classes.scaleText, classes.scaleTextImperial)}
                        >
                            {`${imperialDistance * i}`}
                            {i === imperialCount && ` ${imperialUnit}`}
                        </Typography>
                    ))}
            </Grid>
            <Grid item container className={classes.separatorContainer}>
                {imperialCount &&
                    range(imperialCount + 1).map((i) => (
                        <div
                            key={i}
                            className={classNames(classes.separator, classes.separatorImperial, {
                                [classes.separatorHidden]: i === 0,
                            })}
                        />
                    ))}
            </Grid>
            <Grid item container direction="column" className={classes.barContainerBase}>
                <Grid item container direction="row" wrap="nowrap" className={classes.barContainer}>
                    {imperialCount &&
                        range(imperialCount + 1).map((i) => (
                            <div key={i} className={classes.imperialBar} />
                        ))}
                </Grid>
                <Grid item container direction="row" wrap="nowrap" className={classes.barContainer}>
                    {metricCount &&
                        range(metricCount + 1).map((i) => (
                            <div key={i} className={classes.metricBar} />
                        ))}
                </Grid>
            </Grid>
            <Grid item container className={classes.separatorContainer}>
                {metricCount &&
                    range(metricCount + 1).map((i) => (
                        <div
                            key={i}
                            className={classNames(classes.separator, classes.separatorMetric, {
                                [classes.separatorHidden]: i === 0,
                            })}
                        />
                    ))}
            </Grid>
            <Grid item container className={classes.textContainer}>
                {metricCount &&
                    range(metricCount + 1).map((i) => (
                        <Typography
                            key={i}
                            className={classNames(classes.scaleText, classes.scaleTextMetric)}
                        >
                            {i !== 0 && `${metricDistance * i}`}
                            {i === metricCount && ` ${metricUnit}`}
                        </Typography>
                    ))}
            </Grid>
        </Grid>
    );
}
