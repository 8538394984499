import { Schemas, AttributeTypes } from 'src/visualization/constants';

export const SUPPORTED_ATTRIBUTE_TYPES = [AttributeTypes.Scalar, AttributeTypes.Category];
export const SUPPORTED_SCHEMAS = [
    Schemas.PointsetSchema,
    Schemas.DownholeIntervalsSchema,
    Schemas.DownholeCollectionSchema,
];
export const DRIVER_SUPPORTED_SCHEMAS = [
    Schemas.DownholeIntervalsSchema,
    Schemas.GlobalEllipsoidSchema,
    Schemas.LocalEllipsoidsSchema,
];
