import {
    AttributeColormapAssociation,
    ColormapResponseList,
    ContinuousColormapResponse,
    DiscreteColormapResponse,
} from '@local/api-clients/dist/colormap/enhancedColormapClient';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { AttributeNameColormapsMapType } from './types';

const dataKey = 'data';
const getColormapCollection = 'getColormapCollection';
const getAssociationCollection = 'getAssociationCollection';

const colormapState = (state: RootState) => state.colormap;

function getWorkspaceContinuousColormaps() {
    return createSelector(colormapState, (stateRoot) => {
        const colormaps: (ContinuousColormapResponse | DiscreteColormapResponse)[] = [];
        Object.values(stateRoot.queries).forEach((value) => {
            if (
                value &&
                typeof value === 'object' &&
                'endpointName' in value &&
                value.endpointName === getColormapCollection
            ) {
                if (dataKey in value) {
                    const data = value[dataKey];
                    if (
                        data &&
                        typeof data === 'object' &&
                        'colormaps' in data &&
                        Array.isArray(data.colormaps) &&
                        data.colormaps.length
                    ) {
                        (data as ColormapResponseList).colormaps.forEach((colormap) => {
                            if ('gradient_controls' in colormap || 'end_points' in colormap) {
                                colormaps.push(colormap);
                            } else {
                                console.error(`Unsupported Colormap schema:${colormap.schema}`);
                            }
                        });
                    }
                }
            }
        });
        return colormaps;
    });
}

function getAssociationCollectionByObjectId(objectId: string) {
    return createSelector(colormapState, (stateRoot) => {
        const associations: AttributeColormapAssociation[] = [];
        Object.values(stateRoot.queries).forEach((value) => {
            if (
                value &&
                typeof value === 'object' &&
                'endpointName' in value &&
                value.endpointName === getAssociationCollection &&
                'originalArgs' in value &&
                typeof value.originalArgs === 'object' &&
                value.originalArgs &&
                'objectId' in value.originalArgs &&
                value.originalArgs.objectId === objectId
            ) {
                if (dataKey in value) {
                    const data = value[dataKey];
                    if (
                        data &&
                        typeof data === 'object' &&
                        'associations' in data &&
                        Array.isArray(data.associations) &&
                        data.associations.length
                    ) {
                        associations.push(...data.associations);
                    }
                }
            }
        });
        return associations;
    });
}

export function getAttributeNameColormapsMap(objectId: string) {
    return createSelector(
        getWorkspaceContinuousColormaps(),
        getAssociationCollectionByObjectId(objectId),
        (colormaps, associations) => {
            const attributeNameColormapsMap: AttributeNameColormapsMapType = {};

            associations.forEach((association) => {
                const {
                    attribute_name: attributeName,
                    colormap_id: colormapId,
                    path,
                    modified_at: modifiedAt,
                } = association;

                const timestamp = new Date(modifiedAt).getTime();

                const currentColormap = colormaps.find((colormap) => colormap.id === colormapId);
                if (currentColormap) {
                    if (!(attributeName in attributeNameColormapsMap)) {
                        attributeNameColormapsMap[attributeName] = [
                            { path, colormap: currentColormap, timestamp },
                        ];
                    } else {
                        attributeNameColormapsMap[attributeName].push({
                            path,
                            colormap: currentColormap,
                            timestamp,
                        });
                    }
                } else {
                    console.error(
                        `Colormap with id ${colormapId} not found in workspace colormaps`,
                    );
                }
            });

            Object.values(attributeNameColormapsMap).forEach((colors) => {
                colors.sort((a, b) => b.timestamp - a.timestamp);
            });

            return attributeNameColormapsMap;
        },
    );
}
