import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    card: {
        position: 'relative',
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
        maxWidth: theme.spacing(45),
        height: '492px',
        minWidth: '340px',
        zIndex: 1,
        border: '1px solid #FFF',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '1px',
            right: '1px',
            bottom: '1px',
            left: '1px',
            borderRadius: '3px',
            outline: '1px solid #E2E0E0',
        },
        '&:after': {
            transition: '0.2s ease-in-out',
            content: '""',
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            zIndex: -1,
            margin: '-1px',
            borderRadius: '4px',
            outline: '6px solid #f4f4f4',
        },
        '&:hover': {
            transition: '0.2s ease-in-out',
            color: '#000',
            background: '#FFF',
            cursor: 'pointer',
        },
        '&:hover:after': {
            transition: '0.2s ease-in-out',
            outline: '6px solid #e3e3e3',
            boxShadow: '0px 12px 24px 0px rgba(0, 0, 0, 0.15)',
            WebkitBoxShadow: '0px 12px 24px 0px rgba(0, 0, 0, 0.15)',
        },
    },
    header: {
        backgroundColor: '#16202E',
        borderRadius: '7px 7px 0 0',
        padding: theme.spacing(3),
    },
    body: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: '17px',
        paddingRight: '17px',
        lineHeight: '25px',
    },
    bar: {
        backgroundColor: '#16202E',
        outline: '1px solid rgba(255, 255, 255, 0.3)',
        borderRadius: '5px',
        color: '#FAFAFA',
        height: '44px',
        width: '100%',
    },
    tags: {
        fontSize: '14px',
        padding: '15px 12px 11px 12px',
    },
    login: {
        backgroundColor: 'rgba(249, 250, 252, 0.15)',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '14px',
        zIndex: 10,

        a: {
            color: '#FAFAFA',
            textDecoration: 'none',
            border: '1px solid #3EA0F3',
            borderRadius: '0 5px 5px 0',
            padding: '15px 21px 10px 24px',
            boxShadow: '0 2px 5px 0 rgba(57, 57, 60, 0.1), inset 0 -1px 0 0 rgba(0, 0, 0, 0.1)',
        },
        '&:hover': {
            backgroundColor: 'rgba(253,253,253,0.20)',
        },
    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        height: theme.spacing(10),
        width: '100%',
        textAlign: 'center',
        img: {
            width: '75%',
        },
    },
    status: {
        width: '100%',
        height: '20px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#979797',
        fontSize: '17px',
        fontWeight: 'lighter',
    },
    descriptionPrimary: {
        minHeight: '70px',
        color: '#39393C',
        fontSize: '20px',
        letterSpacing: '-0.31px',
    },
    descriptionSecondary: {
        color: '#39393C',
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: 'lighter',
        letterSpacing: '0.23px',
    },
    footer: {
        backgroundColor: '#F9FAFC',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        margin: `0`,
        border: '1px solid #E2E0E0',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderRadius: '0 0 5px 5px',
    },
    primaryButton: {
        backgroundColor: '#3EA0F3',
        border: '1px solid #92C3DB',
        '&:hover': {
            color: '#FFF',
            background: '#234B66',
            borderRadius: '2px !important',
            outline: '1px solid #234B66',
        },
        whiteSpace: 'nowrap',
        fontSize: '15px !important',
        letterSpacing: '-0.23px !important',
        height: '42px',
        outline: '1px solid #5990B5',
        width: '150px',
        position: 'relative',
        padding: '10px 20px !important',
        borderRadius: '2px !important',
        textAlign: 'center',
    },
    secondaryButton: {
        border: '1px solid transparent',
        borderRadius: '4px !important',
        width: '140px',
        fontSize: '15px !important',
        letterSpacing: '-0.23px !important',
        height: '100%',
    },
    root: {
        backgroundColor: 'transparent',
        overflow: 'hidden',
        zIndex: '1',
    },
    paper: {
        backgroundColor: 'rgba(244, 244, 244, 0.5)',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '6px',
        '&:before': {
            pointerEvents: 'none',
            content: '""',
            position: 'absolute',
            top: '7px',
            right: '7px',
            bottom: '7px',
            left: '7px',
            borderRadius: '3px',
            outline: '1px solid #E2E0E0',
        },
    },
}));
