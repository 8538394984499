import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    DropdownContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3),
    },
    selectMenuButton: {
        border: 'solid 1px black',
        width: '200px',
        backgroundColor: theme.palette.grey[100],
        '&:focus': {
            color: theme.palette.primary.main,
            border: 'solid 1px black',
            backgroundColor: theme.palette.common.white,
        },
    },
    selectMenuLabel: {
        '& > p': {
            fontSize: '14px',
            lineHeight: '15px',
            letterSpacing: 'normal',
            color: theme.palette.secondary.main,
        },
    },
    viewHeader: {
        width: '85px',
    },
}));
