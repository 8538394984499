import type { ListCellComponentProps } from '@local/web-design-system/dist/components/GenericListing';
import Typography from '@mui/material/Typography';

import { getFileExtension } from 'src/utils/fileUtils';

export const DataTypeCell = ({ item, className }: ListCellComponentProps) => (
    <Typography variant="body2" textTransform="uppercase" className={className}>
        {getFileExtension(item.name)}
    </Typography>
);
