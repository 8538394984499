import { MarkerCluster } from '@local/map-viewer/dist/layers/MarkerCluster';
import { ReactMarker } from '@local/map-viewer/dist/layers/ReactMarker';
import * as L from 'leaflet';
import { useState, useMemo, useCallback } from 'react';
import { renderToString } from 'react-dom/server';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';

import { ActiveObjectView } from '../../ActiveObjectView';
import { HoveredObjectView } from '../../HoveredObjectView';
import { ClusterIcon } from './ClusterIcon';
import { ObjectIconGroup } from './ObjectIconGroup';
import { ObjectIconMarker } from './ObjectIconMarker';

const MAX_GROUP_CHILDREN_SHOWN = 7;

function clusterIconFuncFactory(
    renderObjectIconGroup?: (objectIDs: string[], coords?: L.LatLngExpression) => void,
) {
    return (cluster: L.MarkerCluster) =>
        L.divIcon({
            className: 'custom',
            iconSize: undefined,
            html: renderToString(
                <ClusterIcon cluster={cluster} renderObjectIconGroup={renderObjectIconGroup} />,
            ),
        });
}

export function ClusteredObjectMarkers() {
    const { objects, activeObject, toggleActiveObject } = useDiscoverContext();
    const [groupIDs, setGroupIDs] = useState<string[]>([]);
    const [groupCoords, setGroupCoords] = useState<L.LatLngExpression | null>(null);

    const renderObjectIconGroup = useCallback(
        (objectIDs: string[], coords?: L.LatLngExpression) => {
            if (objectIDs.length === 0 || !coords) {
                setGroupIDs([]);
                setGroupCoords(null);
            } else {
                setGroupIDs(objectIDs.slice(0, MAX_GROUP_CHILDREN_SHOWN));
                setGroupCoords(coords);
            }
        },
        [setGroupIDs, setGroupCoords],
    );

    const onObjectMarkerClick = useCallback(
        (objectID: string) => {
            renderObjectIconGroup([]);
            toggleActiveObject(objectID);
        },
        [renderObjectIconGroup, toggleActiveObject],
    );

    const memoizedCluster = useMemo(
        () => (
            <MarkerCluster
                showCoverageOnHover={false}
                iconCreateFunction={clusterIconFuncFactory(renderObjectIconGroup)}
            >
                {objects.map((obj) => (
                    <ObjectIconMarker
                        orgObject={obj}
                        key={obj.object_id}
                        onClick={onObjectMarkerClick}
                    />
                ))}
            </MarkerCluster>
        ),
        [objects, onObjectMarkerClick, renderObjectIconGroup],
    );

    return (
        <>
            {memoizedCluster}
            {groupIDs.length > 0 && groupCoords && (
                <ReactMarker position={groupCoords}>
                    <ObjectIconGroup
                        activeObject={activeObject}
                        onObjectClick={toggleActiveObject}
                        objects={objects.filter((obj) => groupIDs.includes(obj.object_id))}
                    />
                </ReactMarker>
            )}
            <ActiveObjectView ignoredObjects={groupIDs} />
            <HoveredObjectView objects={objects} ignoredObjects={groupIDs} />
        </>
    );
}
