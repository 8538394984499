import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    footerButtonText: {
        fontSize: theme.typography.button.fontSize,
    },
    footerContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    footerButton: {
        maxHeight: theme.spacing(5),
    },
    divider: {
        paddingBottom: theme.spacing(2),
    },
    thickDivider: {
        borderTop: `2px solid ${theme.palette.grey['300']}`,
        borderBottom: '',
    },
    typo: {
        paddingTop: 12,
    },
}));
