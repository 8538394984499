import Typography from '@mui/material/Typography';
import { FormattedDate, FormattedTime } from 'react-intl';

interface Props {
    timestamp?: string;
    automationId?: string;
}

export const PropertyDate = ({ timestamp, automationId }: Props) => (
    <Typography variant="body2" automation-id={automationId}>
        <span style={{ paddingRight: '8px' }}>
            <FormattedDate value={timestamp} month="short" year="numeric" day="2-digit" />
        </span>
        <FormattedTime value={timestamp} hourCycle="h23" />
    </Typography>
);
