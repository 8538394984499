import { BackButton } from '@local/content-area/dist/TitleRow/buttons/BackButton';
import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/dist/urls';
import { useParams } from 'react-router-dom';

import { GenericNotFoundPage } from 'src/pages/notFoundPage/GenericNotFoundPage';
import {
    BACK_TO_WORKSPACES,
    WORKSPACE_NOT_FOUND_MESSAGE,
    WORKSPACE_NOT_FOUND_TITLE,
} from 'src/strings';
import { workspaceListingWithLinkBreadcrumb } from 'src/utils/breadcrumbs';

import { useStyles } from './WorkspaceNotFound.styles';

export const NotFound = () => {
    const { classes } = useStyles();
    const params = useParams();

    const segments: BreadcrumbSegment[] = [...workspaceListingWithLinkBreadcrumb(params)];

    return (
        <>
            <Breadcrumb segments={segments} medium />
            <TitleRow
                title=""
                actions={
                    <div className={classes.button}>
                        <BackButton
                            text={BACK_TO_WORKSPACES}
                            destination={`/${params.orgUuid}/${WORKSPACES_LIST_PAGE}`}
                        />
                    </div>
                }
            />
            <GenericNotFoundPage
                title={WORKSPACE_NOT_FOUND_TITLE}
                message={WORKSPACE_NOT_FOUND_MESSAGE}
            />
        </>
    );
};
