import { theme } from '@local/web-design-system/dist/styles/theme';
import Typography from '@mui/material/Typography';

interface Props {
    text?: string | null;
    automationId?: string;
}

export const PropertyHeader = ({ text, automationId }: Props) => (
    <Typography
        variant="body2"
        width="80px"
        style={{ minWidth: '100px' }}
        color={theme.palette.grey['500']}
        automation-id={automationId}
    >
        {text || ''}:
    </Typography>
);
