import {
    OrgListedObject,
    useEnhancedUpdateObjectByIdMutation,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import {
    FileRestoreIcon,
    ListItem,
    MenuItemConfig,
    NotificationType,
} from '@local/web-design-system';
import { AlertDialog } from '@local/web-design-system/dist/components/Dialog/AlertDialog';
import { EllipsisMenu } from '@local/web-design-system/dist/components/EllipsisMenu';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SerializedError } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    RESTORE_DIALOG_CANCEL,
    RESTORE_DIALOG_CONFIRM,
    RESTORE_DIALOG_CONTENT,
    RESTORE_DIALOG_TITLE,
    RESTORE_DIALOG_WARNING,
    RESTORE_OBJECT_ACTION,
    RESTORE_TOAST_FAILURE,
    RESTORE_TOAST_SUCCESS,
    VIEW_RESTORED_OBJECT,
} from 'src/strings';
import { formatObjectName } from 'src/utils/objectUtils';

import { RecycledObjectDefinition } from '../workspacePage/workspaceContent/FieldRowDefinitions';
import { useStyles } from '../workspacePage/workspaceContent/ObjectRow.styles';

const RestoreAction = () => (
    <Grid
        gap={2}
        container
        alignItems="center"
        justifyContent="flex-start"
        automation-id="object-row-restore-action"
    >
        <FileRestoreIcon />
        <Typography>{RESTORE_OBJECT_ACTION}</Typography>
    </Grid>
);

interface RecycleActionProps {
    onRestore: () => void;
    canRestore?: boolean;
}

const RecycledObjectActions = ({ onRestore, canRestore }: RecycleActionProps) => {
    const menuOptions: MenuItemConfig[] = [
        {
            key: 'restore',
            action: onRestore,
            ItemComponent: RestoreAction,
            isDisabled: () => !canRestore,
        },
    ];

    return (
        <EllipsisMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            menuOptions={menuOptions}
        />
    );
};

interface RecycledObjectRowProps {
    object: OrgListedObject;
}

interface ExtendedError extends SerializedError {
    status?: number;
}

export const RecycledObjectRow = ({ object }: RecycledObjectRowProps) => {
    const params = useParams();
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const [updateObjectsById] = useEnhancedUpdateObjectByIdMutation();
    const [restoreDialogToggle, setRestoreDialogToggle] = useState(false);

    const onRestore = async (confirm: boolean | null) => {
        if (confirm) {
            const response = await updateObjectsById({
                orgId: getOrgUuidFromParams(params),
                workspaceId: object.workspace_id,
                objectId: object.object_id,
                deleted: false,
                geoscienceObject: null,
            });
            const responseError = response?.error as ExtendedError;
            const status = responseError?.status;
            if (status === 303 || !responseError) {
                addMessage({
                    message: RESTORE_TOAST_SUCCESS,
                    type: NotificationType.SUCCESS,
                    children: [
                        <Button
                            component={Link}
                            to={`/${getOrgUuidFromParams(
                                params,
                            )}/workspaces/${getHubForCurrentOrg()}/${object.workspace_id}/overview`}
                            className={classes.recycledToastButtonStart}
                        >
                            {VIEW_RESTORED_OBJECT}
                        </Button>,
                    ],
                    inlineChildren: true,
                });
            } else {
                addMessage({
                    message: RESTORE_TOAST_FAILURE,
                    type: NotificationType.ERROR,
                });
            }
        }
        setRestoreDialogToggle(false);
    };

    return (
        <>
            <ListItem
                key={object.object_id}
                item={object}
                fields={RecycledObjectDefinition}
                actions={
                    <RecycledObjectActions
                        onRestore={() => setRestoreDialogToggle(true)}
                        canRestore={hasRoleOrHigher(object.workspace_access || null, 'editor')}
                    />
                }
            />
            <AlertDialog
                open={restoreDialogToggle}
                icon={<FileRestoreIcon />}
                action={onRestore}
                cancelText={RESTORE_DIALOG_CANCEL}
                confirmText={RESTORE_DIALOG_CONFIRM}
                dialogTitle={RESTORE_DIALOG_TITLE}
                dialogContent={
                    <div>
                        {`${RESTORE_DIALOG_CONTENT} "${formatObjectName(object.name)}".`}
                        <br />

                        {`${RESTORE_DIALOG_WARNING} "${object.workspace_name}".`}
                    </div>
                }
            />
        </>
    );
};
