import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    optionsContainer: {
        padding: theme.spacing(1, 0),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    searchFilterContainer: {
        gap: theme.spacing(1.5),
        width: theme.spacing(53),
    },

    filterByContainer: {
        paddingBottom: theme.spacing(),
        borderLeft: `1px solid ${theme.palette.grey['200']}`,
        borderRight: `1px solid ${theme.palette.grey['200']}`,
    },

    viewModeButtons: {
        borderRight: `1px solid ${theme.palette.grey['200']}`,
        paddingRight: theme.spacing(2),
        marginRight: theme.spacing(3),
    },

    viewButton: {
        color: theme.palette.primary.main,
    },

    currentViewButton: {
        background: theme.palette.grey['200'],
    },

    filterByButton: {
        textTransform: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        display: 'flex',
        height: '44px',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: theme.spacing(20),
    },

    filteredByButton: {
        color: 'white',
        backgroundColor: theme.palette.grey['900'],
        borderRadius: '20px',
        width: '25px',
        marginLeft: theme.spacing(),
    },
    evoViewerButton: {
        marginRight: theme.spacing(1.5),
        width: theme.spacing(25),
        padding: `${theme.spacing(1, 2)} !important`,
    },
}));
