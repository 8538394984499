import {
    OrgListedObject,
    useListObjectsByOrgQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { TitleRow } from '@local/content-area';
import { ErrorScreen } from '@local/svgs/dist/pageState';
import { RecycleBinEmptySvg } from '@local/svgs/dist/svg/RecycleBinEmptySvg';
import { Pagination, SortedList } from '@local/web-design-system';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';
import { FieldDefinition, Order } from '@local/web-design-system/dist/components/GenericListing';
import { BlankTableLoadingSkeleton } from '@local/web-design-system/dist/components/TableLoadingSkeleton/BlankTableLoadingSkeleton';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useObjectSearchParams } from 'src/hooks/useObjectSearchParams';
import { useObjectSortParams } from 'src/hooks/useObjectSortParams';
import { usePagination } from 'src/hooks/usePagination';
import {
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    RECYCLE_BIN_NO_CONTENT,
    RECYCLE_BIN_NO_CONTENT_DESC,
    RECYCLE_BIN_TITLE,
} from 'src/strings';
import { recycleBinBreadcrumb } from 'src/utils/breadcrumbs';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import {
    objectDefinition,
    RecycledObjectDefinition,
} from '../workspacePage/workspaceContent/FieldRowDefinitions';
import { RecycleBinListingControls } from './RecycleBinListingControls';
import { useStyles } from './RecycleBinPage.styles';
import { RecycledObjectRow } from './RecycledObjectRow';

interface OrgObjectListProps {
    objects: OrgListedObject[];
    isLoading: boolean;
    handleSort: (values: { key: string; order: Order }) => void;
    fields?: FieldDefinition[];
}

export const OrgObjectList = ({
    objects,
    isLoading,
    handleSort,
    fields = objectDefinition,
}: OrgObjectListProps) => {
    const { classes } = useStyles();
    if (isLoading) {
        return <BlankTableLoadingSkeleton />;
    }
    if (!isLoading && !objects.length) {
        return (
            <Grid container flexGrow={1} alignItems="center" justifyContent="center">
                <EmptyState
                    title={RECYCLE_BIN_NO_CONTENT}
                    message={RECYCLE_BIN_NO_CONTENT_DESC}
                    image={
                        <div className={classes.noObjectsImage}>
                            <RecycleBinEmptySvg />
                        </div>
                    }
                    classes={{ root: classes.noObjects }}
                />
            </Grid>
        );
    }

    return (
        <SortedList
            itemDef={fields}
            stickyHeader
            isEmpty={!objects.length}
            classes={classes}
            hasActions
            onHeaderSortClickedEventHandler={handleSort}
        >
            {objects.map((object: OrgListedObject) => (
                <RecycledObjectRow object={object} key={object.object_id} />
            ))}
        </SortedList>
    );
};

export function RecycleBinPage() {
    const { classes } = useStyles();
    const params = useParams();

    const {
        page,
        pageSize,
        handleNextPage,
        handlePreviousPage,
        handlePageSizeChange,
        setPaginationParams,
    } = usePagination();

    setDocumentTitle(RECYCLE_BIN_TITLE);

    const segments: BreadcrumbSegment[] = [recycleBinBreadcrumb];

    const { updateObjectSortParams, order, orderBy, key } = useObjectSortParams();

    const { objectName } = useObjectSearchParams();

    const fields = RecycledObjectDefinition.map((field) =>
        field.key === key ? { ...field, defaultSortSettings: { order: order as Order } } : field,
    );

    const handleSort = (values: { key: string; order: Order }) => {
        if (order === values.order && key === values.key) {
            return;
        }

        updateObjectSortParams(values);
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    };

    useEffect(() => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    }, [objectName]);

    const {
        data: gooseData,
        isFetching,
        isError,
    } = useListObjectsByOrgQuery({
        orgId: getOrgUuidFromParams(params),
        deleted: true,
        objectName,
        orderBy,
        offset: page * pageSize,
        limit: pageSize,
        permittedWorkspacesOnly: true,
    });

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <Grid container wrap="nowrap" direction="column">
            <Breadcrumb segments={segments} medium />
            <TitleRow title={RECYCLE_BIN_TITLE} />
            <Grid container direction="column" gap={1} className={classes.hoveringTable}>
                <RecycleBinListingControls>
                    <Pagination
                        numberOfRows={gooseData?.total ?? 0}
                        rowsPerPage={pageSize}
                        page={page}
                        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                        ariaLabelPrevious="Previous Page"
                        ariaLabelNext="Next Page"
                        handleChangeRowsPerPage={handlePageSizeChange}
                        handleBackButtonClick={handlePreviousPage}
                        handleNextButtonClick={handleNextPage}
                    />
                </RecycleBinListingControls>

                <OrgObjectList
                    objects={gooseData?.objects ?? []}
                    isLoading={isFetching}
                    handleSort={handleSort}
                    fields={fields}
                />
            </Grid>
        </Grid>
    );
}
