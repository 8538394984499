import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    iconButton: {
        color: theme.palette.primary.main,
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        marginTop: theme.spacing(2),
        maxWidth: theme.spacing(40),
    },
    emptyContainer: {
        color: theme.palette.primary.dark,
        gap: theme.spacing(2),
        justifyContent: 'center',
    },
    emptyText: {
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        width: '100%',
    },
}));
