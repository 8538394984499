import { Skeleton } from '@local/web-design-system/dist/components/Skeleton/Skeleton';
import Grid from '@mui/material/Grid';

import { useStyles } from './UserContentContainer.styles';

export const ContentLoadingSkeleton = () => {
    const { classes } = useStyles();

    return (
        <Grid container flexDirection="column" className={classes.form} alignContent="flex-start">
            <Grid container>
                <Skeleton variant="rectangle" className={classes.shimmer} width="30%" height="5%" />
            </Grid>
            <Grid container>
                <Skeleton variant="rectangle" className={classes.shimmer} width="10%" height="5%" />
            </Grid>
            <Grid container>
                <Skeleton variant="rectangle" className={classes.shimmer} width="30%" height="5%" />
            </Grid>
            <Grid container flexDirection="row" alignContent="flex-start">
                <Skeleton variant="rectangle" className={classes.shimmer} width="40%" height="5%" />
                <Skeleton variant="rectangle" className={classes.shimmer} width="40%" height="5%" />
            </Grid>
        </Grid>
    );
};
