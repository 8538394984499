export const palette = {
    background: {
        default: '#FFF',
    },
    text: {
        primary: '#000',
    },
    primary: {
        main: '#265c7f',
        light: '#517C98',
        dark: '#30343E',
    },
    secondary: { main: '#1d1f23' },
    success: { main: '#61A887' },
    warning: { main: '#F17248' },
    info: {
        main: '#6183a8',
        light: 'rgba(97, 131, 168, 0.06)', // Have to use rgba, hex uses opacity attribute that applies to whole element.
    },
    error: {
        dark: '#7f2626',
        main: '#E63B38',
    },
    grey: {
        50: '#fafafa',
        100: '#f2f2f2',
        200: '#ebebeb',
        300: '#e2e0e0',
        400: '#c8c8c8',
        500: '#979797',
        600: '#767375',
        700: '#4A4A4C',
        800: '#39393c',
        900: '#1d1f23',
    },
    border: { main: '#aaaaaa' },
};

export const paletteDark = {
    background: {
        default: '#1d1f23',
    },
    text: {
        primary: '#FFF',
    },
    primary: {
        main: '#265c7f',
        light: '#517C98',
    },
    secondary: { main: '#1d1f23' },
    success: { main: '#61A887' },
    warning: { main: '#F17248' },
    info: {
        main: '#6183a8',
        light: 'rgba(97, 131, 168, 0.06)', // Have to use rgba, hex uses opacity attribute that applies to whole element.
    },
    error: {
        dark: '#7f2626',
        main: '#E63B38',
    },
    grey: {
        50: '#fafafa',
        100: '#f2f2f2',
        200: '#ebebeb',
        300: '#e2e0e0',
        400: '#c8c8c8',
        500: '#979797',
        600: '#767375',
        700: '#4A4A4C',
        800: '#39393c',
        900: '#1d1f23',
    },
    border: { main: '#aaaaaa' },
};
