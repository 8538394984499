import FileIcon from '@local/web-design-system/dist/icons/Files/FileIcon';
import Typography from '@mui/material/Typography';

import type { DownloadFileResponse } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { Header } from 'src/components/sidebar/Header';
import { useStyles } from 'src/components/sidebar/Properties.styles';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { PropertyHeader } from 'src/components/sidebar/PropertyHeader';
import { PropertyText } from 'src/components/sidebar/PropertyText';
import { FileActions } from 'src/pages/workspaceFilesPage/fileActions/FileActions';
import {
    TYPE,
    FILE_SIZE,
    FILE_VERSION,
    MODIFIED_BY,
    LAST_MODIFIED,
    FILE_PROPERTIES,
} from 'src/strings';
import { formatBytes, getFileExtension } from 'src/utils/fileUtils';

interface Props {
    file?: DownloadFileResponse;
}

export const Properties = ({ file }: Props) => {
    const { classes } = useStyles();

    if (!file) {
        return null;
    }

    const fileSize = formatBytes(file.size);
    return (
        <div>
            <Header text={FILE_PROPERTIES} />
            <div className={classes.header}>
                <FileIcon color="primary" />
                <Typography
                    className={classes.propertiesNameHeader}
                    automation-id="file-name"
                    title={file.name}
                >
                    {file.name}
                </Typography>
            </div>
            <div className={classes.properties}>
                <PropertyHeader text={TYPE} automationId="file-type-label" />
                <PropertyText
                    text={getFileExtension(file.name).toUpperCase()}
                    automationId="file-extension"
                />
                <PropertyHeader text={FILE_SIZE} automationId="file-size-label" />
                <PropertyText text={fileSize} automationId="file-size-value" />
                <PropertyHeader text={FILE_VERSION} automationId="file-version-label" />
                <PropertyText
                    text={`V${(file?.versions?.length || 1).toString()}`}
                    automationId="file-version-value"
                />
                {file?.versions && file?.versions?.length > 0 && (
                    <>
                        <PropertyHeader text={LAST_MODIFIED} automationId="last-modified-label" />
                        <PropertyDate
                            timestamp={file.versions[0]?.created_at}
                            automationId="last-modified-value"
                        />
                        <PropertyHeader text={MODIFIED_BY} automationId="modified-by-label" />
                        <PropertyText
                            text={file.versions[0]?.created_by?.name}
                            automationId="modified-by-value"
                        />
                    </>
                )}
            </div>
            <FileActions file={file} icons />
        </div>
    );
};
