import { common } from '@local/web-design-system/src/styles/common';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    titleRow: {
        lineHeight: 1.15,
        fontWeight: 'bold',
        fontSize: '16px',
        fontFamily: 'sans-serif',
    },

    emptyStateWrapper: {
        padding: '0 50px',
    },
    emptyStateRoot: {
        position: 'relative',
    },
    emptyStateBody: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, 0)',
    },
    hoveringTable: {
        ...common(theme).hoveringTable,
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    subheader: {
        paddingTop: theme.spacing(2),
    },

    workspaceListingPage: {
        margin: theme.spacing(2.5),
    },

    viewButton: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[700],
        '&:hover': {
            color: theme.palette.grey[700],
            backgroundColor: theme.palette.grey[100],
        },
    },

    currentViewButton: {
        color: theme.palette.primary.main,
        background: theme.palette.grey[200],
        '&:hover': {
            color: theme.palette.primary.main,
            background: theme.palette.grey[200],
        },
    },
    title: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
    },
    paginationButtons: {
        margin: 0,
    },
    searchOverrides: {
        color: theme.palette.grey[700],

        '&::placeholder': {
            fontStyle: 'normal',
            opacity: 1,
        },
    },
    searchRootOverrides: {
        height: '40px',
    },
}));
