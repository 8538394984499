import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    skeleton: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));
