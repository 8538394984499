import { common } from '@local/web-design-system/dist/styles/common';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    noSearchResults: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(14),
    },
    noSearchResultsImage: {
        margin: 'auto',
        width: '50%',
    },
    paginationContainer: {
        padding: theme.spacing(2),
        justifyContent: 'end',
    },
    table: {
        ...common(theme).thinScrollBar,
        maxHeight: '71vh',
        scrollbarGutter: 'stable',
    },
}));
