import { makeStyles } from 'tss-react/mui';

interface StyleParams {
    fullBarWidth: number;
    metricBarWidth: number;
    imperialBarWidth: number;
}

export const useStyles = makeStyles<StyleParams>()(
    (_theme, { metricBarWidth, imperialBarWidth, fullBarWidth }) => ({
        base: {
            width: 'auto',
            padding: '8px',
            borderRadius: '8px',
            backgroundColor: '#1d1f23',
            boxShadow: '0px 8px 14px 0px #00000040',
        },
        barContainer: {
            'div:nth-of-type(odd)': {
                backgroundColor: '#4a4a4c',
            },
            'div:nth-of-type(even)': {
                backgroundColor: '#fafafa',
            },
            border: '1px solid #4a4a4c',
            position: 'relative',
        },
        textContainer: {
            'p:last-of-type': {
                maxWidth: 'unset',
                paddingRight: '0px !important',
            },
        },
        separatorContainer: {
            'div:last-of-type': {
                paddingRight: '0px !important',
            },
        },
        scaleText: {
            fontFamily: 'Inter',
            fontSize: '10px',
            fontWeight: 400,
            lineHeight: '12px',
            color: 'white',
            maxWidth: '0px',
        },
        scaleTextMetric: {
            paddingRight: `${metricBarWidth}px`,
        },
        scaleTextImperial: {
            paddingRight: `${imperialBarWidth}px`,
        },
        separator: {
            maxWidth: '0px',
            '&::before': {
                content: '""',
                display: 'block',
                height: '2px',
                width: '1px',
                backgroundColor: '#4a4a4c',
            },
        },
        separatorMetric: {
            paddingRight: `${metricBarWidth}px`,
        },
        separatorImperial: {
            paddingRight: `${imperialBarWidth}px`,
        },
        separatorHidden: {
            '&::before': {
                height: '0px',
                width: '0px',
            },
        },
        metricBar: {
            height: '5px',
            minWidth: `${metricBarWidth}px`,
        },
        imperialBar: {
            height: '5px',
            minWidth: `${imperialBarWidth}px`,
        },
        barContainerBase: {
            width: `${fullBarWidth}px`,
            overflow: 'hidden',
        },
    }),
);
