/**
 * waitForMs is useful as a delay between repeated api calls. The waitForMs is useful
 * to ensure that the endpoint is hit and returns a response before a subsequent call is made.
 */
export const waitForMs = (milliseconds: number) =>
    new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
