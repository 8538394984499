import { fonts } from '@local/web-design-system/dist/styles';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const breakpoint = 'xs';
const itemBreakpoint = (theme: Theme) => theme.breakpoints.down(breakpoint);

export const styles = (theme: Theme) => ({
    tableHeader: {
        [itemBreakpoint(theme)]: {
            display: 'none',
        },
        backgroundColor: theme.palette.grey[700],
        '& tr': { height: '56px' },
    },
    headerText: {
        ...fonts.tableHeader,
        color: theme.palette.common.white,
    },
    headerCell: {
        padding: theme.spacing(),
        backgroundColor: theme.palette.grey[700],
        borderBottom: 'none',
        lineHeight: '1rem',
    },
});

export const useStyles = makeStyles()(styles);
