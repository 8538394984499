import { REGISTER_INTEREST, REQUEST_A_DEMO, REQUEST_A_TRIAL } from 'src/strings';

export const products = [
    {
        name: 'BlockSync',
        logoName: 'BlockSyncLogoRGB',
        tags: ['Mining'],
        isIntegration: false,
        loginLink: 'https://blocksync.seequent.com/',
        status: 'eap',
        descriptionPrimary: 'Block model data management solution',
        descriptionSecondary:
            'BlockSync provides a world-class data management for both exploration and mining operations',
        learnMore: {
            callToAction:
                'BlockSync is a data management solution enabling a cloud connected block model for unparalleled collaboration, data integration and governance.',
            descriptionTitle: 'The next level of collaboration',
            description:
                'Unlike storing block models on a network drive, BlockSync is built to connect to any application and can support teams of users updating the same model, so that you can focus on making decisions rather than managing files.',
            featuresList: [
                'Support for merged subset updates into a shared block model so you can easily collaborate in real-time',
                'Model versioning secures your models providing full auditability and peace of mind',
                'Gain rapid insights into your block models with a easy-to-use API for scripting and integration with 3rd party systems.',
            ],
        },
        cta: {
            label: REGISTER_INTEREST,
            link: 'https://seequent.qualtrics.com/jfe/form/SV_2mDGAqRaeuY2qea',
        },
    },
    {
        name: 'MxDeposit',
        logoName: 'MXDepositLogoWhite',
        tags: ['Mining'],
        isIntegration: false,
        loginLink: 'https://app.mxdeposit.net/login?next=http:%2F%2Fapp.mxdeposit.net%2F',
        status: 'active',
        descriptionPrimary: 'Real-time collaboration and decision-making',
        descriptionSecondary:
            'Collect, manage, share, and access your drillhole and point sample data in the cloud with MX Deposit.',
        learnMore: {
            callToAction:
                'MX Deposit is a data management platform that enables you to collect, manage, share, and access your drillhole and point sample data in the cloud.',
            descriptionTitle: 'The next level of collaboration',
            description:
                'A true SaaS solution, MX Deposit enhances collaboration and efficiency across project teams and transforms the way data is managed across the entire mining value chain.',
            featuresList: [
                'Real-time decision-making within a single source of truth',
                'Mobile and web-based logging provides flexibility across location, device, and connectivity',
                'Configurable to meet your project needs and scalable to meet your project numbers',
            ],
        },
        cta: {
            label: REQUEST_A_DEMO,
            link: 'https://www.seequent.com/products-solutions/mx-deposit/enquire/',
        },
    },
    {
        name: 'Imago',
        logoName: 'ImagoLogoWhite',
        tags: ['Mining'],
        isIntegration: false,
        loginLink: 'https://imago.seequent.com/login',
        status: 'active',
        descriptionPrimary: 'Validate data insights with geoscientific imagery',
        descriptionSecondary:
            'Easily access, collaborate, and validate drillhole and sample data using your high-quality geoscientific imagery.',
        learnMore: {
            callToAction:
                'Designed to help mining companies manage their high volume of geological images, Imago is a cloud-based platform that extracts knowledge from geoscientific imagery.',
            descriptionTitle: 'The next level of collaboration',
            description:
                'Imago consolidates images from any source, transforms them into meaningful insights, and connects them to the appropriate geological or mining activity.',
            featuresList: [
                'Imago captures your images and transfers them to your laptop or PC',
                'Your virtual coreshed - images are automatically labelled, inspected, cropped and catalogued',
                'Validate your geological models with Imago images by connecting to your geological tool of choice',
            ],
        },
        cta: {
            label: REQUEST_A_TRIAL,
            link: 'https://www.seequent.com/products-solutions/imago/enquire/',
        },
    },
    {
        name: 'Central',
        logoName: 'CentralLogoWhite',
        tags: ['Cloud Service', 'Collaboration'],
        isIntegration: false,
        loginLink: 'https://central.seequent.com/',
        status: 'active',
        descriptionPrimary: 'Bring teams and data together with Seequent Central',
        descriptionSecondary:
            'Seequent Central enables connected workflows, real time collaboration, and effective data management.',
        learnMore: {
            callToAction:
                'Cloud-based Seequent Central is an enabler of connected workflows, shared 3D visualisation, and team collaboration, no matter your location.',
            descriptionTitle: 'The next level of collaboration',
            description:
                'Designed to help teams manage complex geological data, Central sits at the heart of the modelling process, ' +
                'bringing together insights and effective data management within an auditable environment.',
            featuresList: [
                'Data management and versioning',
                'Real-time peer review and collaboration',
                'Decision-making platform and Central data hub',
            ],
        },
        cta: {
            label: REQUEST_A_TRIAL,
            link: 'https://www.seequent.com/products-solutions/seequent-central/trial/',
        },
    },
];
