// Hardcoded as api endpoint not available yet.
export const filterTypeMetaData = {
    name: 'Type',
    category: 'type',
    options: [
        {
            description: '2D/3D Geometry',
            comparison: ['design-geometry'],
        },
        {
            description: 'Downhole',
            comparison: ['downhole-collection', 'exploratory-location', 'downhole-intervals'],
        },
        {
            description: 'Geophysics',
            comparison: ['geophysical-records-1d'],
        },
        {
            description: 'Lines',
            comparison: ['line-segments'],
        },
        {
            description: 'Geostatistics/Distributions',
            comparison: ['non-parametric-continuous-cumulative-distribution'],
        },
        {
            description: 'Points',
            comparison: ['pointset'],
        },
        {
            description: 'Grids',
            comparison: [
                'regular-2d-grid',
                'regular-3d-grid',
                'regular-masked-3d-grid',
                'tensor-2d-grid',
                'tensor-3d-grid',
                'unstructured-grid',
                'unstructured-hex-grid',
                'unstructured-quad-grid',
                'unstructured-tet-grid',
            ],
        },
        {
            description: 'Mesh',
            comparison: ['triangle-mesh'],
        },
        {
            description: 'Geostatistics/Variograms',
            comparison: ['variogram'],
        },
        {
            description: 'Geological Model',
            comparison: ['geological-model-meshes'],
        },
    ],
};
