import {
    DockPosition,
    PLOT_GRID_AREA,
} from '@local/web-design-system/dist/components/DockableContainer/DockableContainer.constants';
import { BASE_Z_INDEX } from '@local/web-design-system/dist/hooks/useZIndex';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        toolbar: {
            zIndex: 1,
            gridArea: PLOT_GRID_AREA,
        },
        tools: {
            zIndex: BASE_Z_INDEX + 2,
            position: 'relative',
            float: 'right',
            background: 'white',
            padding: theme.spacing(0.5),
            top: theme.spacing(0.5),
            right: 10,
            pointerEvents: 'auto',
        },
        sidebarPlaceholder: {
            outline: `1px solid ${theme.palette.grey[200]}`,
            outlineOffset: '-1px',
            gridArea: DockPosition.BOTTOM,
        },
        orientationArrows: {
            gridArea: PLOT_GRID_AREA,
        },
        scalebar: {
            gridArea: PLOT_GRID_AREA,
        },
    }) as const;

export const useStyles = makeStyles()(styles);
