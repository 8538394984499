/** Default pagination page size options to be used across the Evo app */
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const DEFAULT_PAGE_SIZE = 20;

/**
 * Parameters for pagination. Implement as a state container to avoid concurrent state updates
 * causing unwanted re-renders.
 */
export interface PaginationParams {
    page: number;
    pageSize: number;
    searchTerm: string;
}
