import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CloseIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <path
            d="M13.3167792,12.0293955 L22.5927278,2.75351057 C22.9472955,2.39780154 22.9478673,1.82191892
            22.5927278,1.46678177 C22.2358725,1.11107274 21.6605578,1.11107274 21.3048464,1.46678177
            L12.0294697,10.7420948 L2.75352122,1.46678177 C2.3995254,1.11107274 1.82192318,1.11107274
            1.4667836,1.46678177 C1.11107213,1.82191892 1.11107213,2.39780154 1.4667836,2.75351057
            L10.7421602,12.0293955 L1.46735548,21.3041367 C1.11164402,21.6598457 1.11164402,22.2357283
            1.46735548,22.5920092 C1.64521122,22.7692918 1.87796775,22.8590769 2.11072429,22.858505
            C2.34405271,22.8590769 2.57680925,22.7692918 2.7540931,22.5920092 L12.0294697,13.3166962
            L21.3048464,22.5920092 C21.4827021,22.7692918 21.7160305,22.8590769 21.9487871,22.8590769
            C22.1809717,22.8590769 22.4143001,22.7692918 22.5927278,22.5920092 C22.9478673,22.2357283
            22.9478673,21.6598457 22.5921559,21.3041367 L13.3167792,12.0293955 Z"
        />
    </SvgIcon>
);

export default CloseIcon;
