import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useRef } from 'react';

import { useTrace } from '@seequent/web-design-system/components/utils/trace';
import { ArrowDown, ArrowUp } from '@seequent/web-design-system/icons/Arrows';

import { useStyles } from './DropdownContainer.styles';

export interface DropdownContainerProps {
    isOpen: boolean;
    label: string;
    children?: React.ReactNode;
    buttonChildren?: React.ReactNode;
    endIcon?: React.ReactNode;
    useFooterButton?: boolean;
    footerButtonLabel?: string;
    footerButtonCallback?: (event: any) => void;
    onOpen: () => void;
    onClose: () => void;
    maxWidth?: string;
    width?: string;
    maxHeight?: string;
    maxButtonWidth?: string;
    noPadding?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
}

export const DropdownContainer = ({
    isOpen,
    label,
    children,
    buttonChildren,
    endIcon,
    useFooterButton,
    footerButtonLabel,
    footerButtonCallback,
    onOpen,
    onClose,
    maxWidth,
    width,
    maxHeight,
    maxButtonWidth,
    noPadding,
    variant,
}: DropdownContainerProps) => {
    const { classes } = useStyles({
        width,
        maxWidth,
        maxHeight,
        maxButtonWidth,
        noPadding,
        variant,
    });
    const labelButtonRef = useRef(null);
    const applyTrace = useTrace('dropdown-container');

    const Icon = isOpen ? ArrowUp : ArrowDown;

    const textClass = isOpen ? classes.buttonTextOpen : classes.buttonText;

    const footer = () => (
        <>
            <Divider light={false} />
            <Grid className={classes.footerContainer}>
                <Button
                    className={classes.footerButton}
                    size="large"
                    onClick={footerButtonCallback}
                    color="primary"
                    variant="contained"
                >
                    <Typography className={classes.footerButtonText}>
                        {footerButtonLabel}
                    </Typography>
                </Button>
            </Grid>
        </>
    );

    return (
        <Grid className={isOpen ? classes.containerOpen : classes.container}>
            <Button
                ref={labelButtonRef}
                variant={variant === 'outlined' ? 'outlined' : 'text'}
                onClick={isOpen ? onClose : onOpen}
                className={isOpen ? classes.buttonOpen : classes.button}
                {...applyTrace(label)}
            >
                <Typography className={textClass}>{label}</Typography>
                {buttonChildren}
                {endIcon ?? <Icon className={textClass} />}
            </Button>
            <Popover
                open={isOpen}
                anchorEl={labelButtonRef?.current}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid className={classes.bodyContainer}>
                    {children}
                    {useFooterButton && footer()}
                </Grid>
            </Popover>
        </Grid>
    );
};

export default DropdownContainer;
