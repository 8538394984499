import { common } from '@local/web-design-system/dist/styles/common';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: {
        ...common(theme).thinScrollBar,
        backgroundColor: '#fafafa',
    },
    errorArea: {
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'white',
    },
    contentArea: {
        overflow: 'hidden',
        padding: `0 ${theme.spacing(2)} ${theme.spacing(8)}`,
        '@media (min-width: 1400px)': {
            padding: `0 10% ${theme.spacing(8)}`,
        },
        '@media (min-width: 1800px)': {
            padding: `0 15% ${theme.spacing(8)}`,
        },
    },
    productSection: {
        position: 'relative',
    },
    bodyImage: {
        backgroundImage: 'url("Mesh-BK-Body-x2.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        left: '0',
        height: '1100px',
        width: '100%',
        maxWidth: '800px',
    },
}));
