import { fonts } from '@local/web-design-system/dist/styles';
import { common } from '@local/web-design-system/src/styles/common';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    hoveringTable: {
        ...common(theme).hoveringTable,
    },
    backButton: {
        paddingBottom: theme.spacing(),
    },
    formColumn: {
        borderRight: `1px solid ${theme.palette.grey[200]}`,
        [theme.breakpoints.up('lg')]: {
            height: '100%',
        },
    },
    headerRow: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(),
        maxHeight: theme.spacing(5),
        minHeight: theme.spacing(5),
    },
    headerRowText: {
        fontSize: '16px',
        fontWeight: fonts.weights.regular,
    },
    form: {
        ...common(theme).thinScrollBar,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
        height: '100%',
        overflowY: 'auto',
    },
    shimmer: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
    },
    permissionContainer: {
        height: '100%',
    },
    contentRow: {
        flexBasis: 'auto',
        height: '100%',
    },
}));
