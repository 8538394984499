import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import {
    CONTACT_US,
    EVO_ENQUIRE,
    EVO_ENQUIRE_LINK,
    EVO_ENQUIRE_SUBTEXT,
    EVO_ENQUIRE_SUBTEXT2,
} from '../../../strings';
import { useStyles } from './enquireForm.styles';

export const ENQUIRE_ID = 'enquire-form';

export const Enquire = () => {
    const { classes } = useStyles();

    return (
        <Grid
            container
            className={classes.enquireBody}
            justifyContent="center"
            direction="column"
            alignItems="center"
            id={ENQUIRE_ID}
        >
            <Grid item className={classes.enquireHeader}>
                {EVO_ENQUIRE}
            </Grid>
            <Grid item className={classes.enquireSubHeader}>
                {EVO_ENQUIRE_SUBTEXT}
                <Link href={EVO_ENQUIRE_LINK} target="_blank" className={classes.enquireLink}>
                    {CONTACT_US}
                </Link>
                {EVO_ENQUIRE_SUBTEXT2}
            </Grid>
        </Grid>
    );
};
