import { OrgListedObject } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { EllipsisMenu } from '@local/web-design-system/dist/components/EllipsisMenu';
import { MenuItemConfig } from '@local/web-design-system/dist/components/Menu';
import { MoveToBinIcon, DriverIcon, ViewerIcon } from '@local/web-design-system/dist/icons';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useParams } from 'react-router-dom';

import { OPEN_IN_DRIVER_ACTION, OPEN_IN_VIEWER_ACTION, RECYCLE_OBJECT_ACTION } from 'src/strings';
import { isSchemaDriverViewable, isSchemaViewable } from 'src/utils/extractSchema';
import { createNavigateToDriver, createNavigateToViewer } from 'src/utils/viewObjectUtils';

import useStyles from './ObjectActions.styles';
import { ListedObjectDisplay } from './utils';

interface ObjectActionProps {
    object: OrgListedObject | ListedObjectDisplay;
    onRecycle: () => void;
}

interface DynamicMenuItemConfig extends MenuItemConfig {
    shouldRender: boolean;
}

function RecycleAction() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.objectActionGrid} automation-id="object-row-delete-action">
            <MoveToBinIcon />
            <Typography className={classes.objectActionText}>{RECYCLE_OBJECT_ACTION}</Typography>
        </Grid>
    );
}

function OpenInDriverAction() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.objectActionGrid} automation-id="object-row-open-in-driver-action">
            <DriverIcon />
            <Typography className={classes.objectActionText}>{OPEN_IN_DRIVER_ACTION}</Typography>
        </Grid>
    );
}

function OpenInViewerAction() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.objectActionGrid} automation-id="object-row-open-in-viewer-action">
            <ViewerIcon />
            <Typography className={classes.objectActionText}>{OPEN_IN_VIEWER_ACTION}</Typography>
        </Grid>
    );
}

function filterAndMergeOptions(
    preDividerOptions: DynamicMenuItemConfig[],
    postDividerOptions: DynamicMenuItemConfig[],
): MenuItemConfig[] {
    const filteredPreDividerOptions = preDividerOptions.filter((option) => option.shouldRender);
    const filteredPostDividerOptions = postDividerOptions.filter((option) => option.shouldRender);

    // The divider is set using this method instead of in the originally defined options in order to ensure that the divider is
    // always placed after the last post-filtered divider option. Since some may be filtered out depending on the object schema.
    if (filteredPreDividerOptions.length > 0 && filteredPostDividerOptions.length > 0) {
        filteredPreDividerOptions[filteredPreDividerOptions.length - 1].divideAfter = true;
    }

    // Combine the filtered and divided options into a single list for display in a menu.
    return [...filteredPreDividerOptions, ...filteredPostDividerOptions];
}

export function ObjectActions({ object, onRecycle }: ObjectActionProps) {
    const featureFlags = useFlags();
    const { evouiEnableDelete, evouiEnableGooseRendering, evouiOpenInDriver } = featureFlags;
    const { hostname } = window.location;
    const params = useParams();
    const navigate = useNavigate();
    const { classes } = useStyles();

    // Options are split into pre and post divider options to allow for a dynamic divider to appear between the options
    // depending on which options are rendered for a given object.
    const preDividerOptions: DynamicMenuItemConfig[] = [
        {
            key: 'openInViewer',
            action: createNavigateToViewer(
                navigate,
                params.hubCode,
                params.workspaceUuid,
                object.object_id,
            ),
            ItemComponent: OpenInViewerAction,
            shouldRender:
                evouiEnableGooseRendering && isSchemaViewable(object.schema, featureFlags),
            dividerClassName: classes.divider,
        },
        {
            key: 'openInDriver',
            action: createNavigateToDriver(
                hostname,
                params.orgUuid,
                params.hubCode,
                params.workspaceUuid,
                object.object_id,
            ),
            ItemComponent: OpenInDriverAction,
            shouldRender: evouiOpenInDriver && isSchemaDriverViewable(object.schema),
            dividerClassName: classes.divider,
        },
    ];
    const postDividerOptions: DynamicMenuItemConfig[] = [
        {
            key: 'recycle',
            action: onRecycle,
            ItemComponent: RecycleAction,
            shouldRender: evouiEnableDelete,
        },
    ];

    const menuOptions: MenuItemConfig[] = filterAndMergeOptions(
        preDividerOptions,
        postDividerOptions,
    );

    if (!menuOptions.length) {
        return null;
    }

    return (
        <EllipsisMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: -20,
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            menuOptions={menuOptions}
        />
    );
}
