import { NotificationType, Notification } from '@local/web-design-system/dist/components';
import { FileIcon } from '@local/web-design-system/dist/icons';
import { theme } from '@local/web-design-system/dist/styles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

import CloseIcon from 'src/assets/CloseIcon';
import { UploadStatusIcon } from 'src/components/sidebar/UploadStatusIcon';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { UPLOADING_FILES, UPLOAD_MORE_FILES } from 'src/strings';
import { FileStatus, UploadStatus } from 'src/types/files';
import { formatBytes } from 'src/utils/fileUtils';

import { useStyles } from './ProgressPanel.styles';
import { UploadFilesButton } from './UploadFilesButton';

interface Props {
    workspaceId: string;
}

export const ProgressPanel = ({ workspaceId }: Props) => {
    const { classes } = useStyles();
    const {
        fileUploads,
        failedCount,
        uploadedCount,
        uploadingCount,
        completedCount,
        clearFileUploads,
        upsertFileStatus,
    } = useFileUploadContainer({ workspaceId });

    const updatePercentCompleted = (fileId: string, newPercentCompleted: number) => {
        const fileState = fileUploads.find((file: FileStatus) => file.fileId === fileId);
        if (fileState && fileState.uploadStatus === UploadStatus.Uploading) {
            const updatedFile = {
                ...fileState,
                percentCompleted: Math.min(newPercentCompleted, 95),
            };
            upsertFileStatus(updatedFile);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            fileUploads.forEach((file: FileStatus) => {
                // this function makes the loading indicator increment over a 30s period
                // if the file is still not uploaded it hangs on 95% the query times out after 2mins
                if (file.uploadStatus === UploadStatus.Uploading && file.percentCompleted < 95) {
                    updatePercentCompleted(file.fileId, file.percentCompleted + 100 / 30);
                }
            });

            const allUploadsFinished = fileUploads.every(
                (file: FileStatus) =>
                    file.uploadStatus === UploadStatus.Uploaded ||
                    file.uploadStatus === UploadStatus.Failed,
            );
            if (allUploadsFinished) {
                clearInterval(timer);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [fileUploads]);

    return (
        <Grid className={classes.uploadContainer} automation-id="uploading-files">
            <div className={classes.uploadingHeader}>
                <Typography>{UPLOADING_FILES}</Typography>
                {uploadingCount === 0 && (
                    <IconButton
                        onClick={clearFileUploads}
                        automation-id="close-button"
                        className={classes.closeIconButton}
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                )}
            </div>
            <Grid className={classes.uploadContent}>
                <Grid className={classes.uploadMoreButton} automation-id="upload-more-button">
                    <UploadFilesButton buttonText={UPLOAD_MORE_FILES} secondary />
                </Grid>
                {completedCount > 0 && (
                    <Grid className={classes.notificationContainer}>
                        {uploadedCount > 0 && (
                            <Grid automation-id="success-notifications">
                                <Notification
                                    message={`${uploadedCount} file${uploadedCount > 1 ? 's' : ''} ${
                                        uploadedCount > 1 ? 'have' : 'has'
                                    } been uploaded`}
                                    type={NotificationType.SUCCESS}
                                />
                            </Grid>
                        )}
                        {failedCount > 0 && (
                            <Grid automation-id="failed-notifications">
                                <Notification
                                    message={`${failedCount} file${failedCount > 1 ? 's' : ''} ${
                                        failedCount > 1 ? 'have' : 'has'
                                    } failed to upload`}
                                    type={NotificationType.ERROR}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid container className={classes.uploadList} flexWrap="nowrap">
                    {fileUploads.map((file: FileStatus) => (
                        <Grid
                            item
                            automation-id="file-upload-container"
                            key={`${file.fileId}`}
                            className={classes.progressContainer}
                        >
                            <div className={classes.linearProgressContainer}>
                                <div className={classes.textContainer}>
                                    <FileIcon color="primary" />
                                    <div className={classes.fileNameContainer}>
                                        <Typography
                                            variant="h4"
                                            className={classes.fileName}
                                            title={file.fileName}
                                            automation-id="file-upload-file-name"
                                        >
                                            {file.fileName}
                                        </Typography>
                                    </div>
                                    <Typography
                                        variant="body2"
                                        style={{ color: theme.palette.grey[500] }}
                                        automation-id="file-upload-file-size"
                                    >
                                        {formatBytes(file.size)}
                                    </Typography>
                                </div>
                                <Grid
                                    automation-id="linear-progress-faked"
                                    className={classes.linearProgress}
                                >
                                    <LinearProgress
                                        variant="determinate"
                                        value={file.percentCompleted}
                                    />
                                </Grid>
                                <Grid
                                    automation-id="upload-status"
                                    className={classes.textContainer}
                                >
                                    <Typography variant="body2" className={classes.uploadStatus}>
                                        {file.uploadStatus}
                                    </Typography>
                                    {file.percentCompleted > 0 && (
                                        <Typography
                                            automation-id="percent-completed"
                                            variant="body2"
                                            color={theme.palette.primary.main}
                                        >
                                            {`${file.percentCompleted.toFixed(0)}%`}
                                        </Typography>
                                    )}
                                </Grid>
                            </div>
                            <UploadStatusIcon status={file.uploadStatus} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};
