/**
 * Returns the object name without the .json extension.
 */
export function formatObjectName(name: string) {
    // Object names end with .json, so we remove the last 5 characters.
    return name.substring(0, name.length - 5);
}

/**
 * Returns a "human friendly" version of the object schema
 */
export function formatObjectSchema(schema: string) {
    const schemaType = schema.split('/')[2];

    return schemaType
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
