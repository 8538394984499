import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';

import { useStyles } from './GenericNotFound.styles';

export function GenericNotFoundPage({ title, message }: { title: string; message: string }) {
    const { classes } = useStyles();

    return (
        <div className={classes.hoveringTable}>
            <EmptyState image={<NotFoundSvg />} title={title} message={message} />
        </div>
    );
}
