import { trackUserAction } from '@local/metrics';
import { FilterSearch } from '@local/web-design-system/dist/components/FilterSearch';

import { useStyles } from './WorkspaceListingPage.styles';

interface WorkspaceSearchProps {
    setSearchTerm: (term: string) => void;
    searchPlaceHolderValue: string;
    userAction: string;
    userActionCategory: string;
    defaultValue?: string;
    automationId: string;
}

export function WorkspaceSearch({
    setSearchTerm,
    searchPlaceHolderValue,
    userAction,
    userActionCategory,
    defaultValue,
    automationId,
}: WorkspaceSearchProps) {
    const { classes } = useStyles();
    function trackedSearch(term: string) {
        setSearchTerm(term);
        // Don't actually track what they type as its PII
        trackUserAction(userAction, {
            label: 'User typed something in the search box',
            category: userActionCategory,
        });
    }
    return (
        <FilterSearch
            searchBarOnly
            disabled={false}
            onSearchCallBack={(value) => trackedSearch(value)}
            searchPlaceHolderValue={searchPlaceHolderValue}
            automation-id={automationId}
            inputClasses={{
                customInput: classes.searchOverrides,
                root: classes.searchRootOverrides,
            }}
            defaultValue={defaultValue}
        />
    );
}
