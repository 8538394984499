import { common } from '@local/web-design-system/dist/styles/common';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: { backgroundColor: theme.palette.grey[50] },
    searchColumn: { width: '55%' },
    search: { maxWidth: '100%', minWidth: theme.spacing(25) },
    wideSearch: { maxWidth: '75%', minWidth: theme.spacing(25) },
    column: { paddingLeft: 0, paddingRight: 0 },
    button: { width: theme.spacing(21), paddingRight: theme.spacing(2) },
    icon: { marginRight: theme.spacing() },
    workspaceButton: { color: theme.palette.primary.contrastText },
    text: { width: theme.spacing(20), paddingY: theme.spacing(0.5) },
    selector: {
        minWidth: theme.spacing(15),
        paddingTop: theme.spacing(0.5625),
        paddingBottom: theme.spacing(0.5625),
        paddingLeft: theme.spacing(),
    },
    menuList: {
        ...common(theme).thinScrollBar,
    },
}));
