import { useSidebarContext } from '@local/content-area/dist/Sidebar/SidebarContext';
import { trackUserAction } from '@local/metrics/dist/src/metrics';
import { SelectMenu } from '@local/web-design-system/dist/components/SelectMenu';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { Schema } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import Typography from '@mui/material/Typography';

import { Categories, UserAction } from 'src/metrics.types';
import { VIEW } from 'src/strings';

import { useStyles } from './DropDown.styles';

interface DropDownProps {
    schemas: Schema[];
    selected: string;
    setSelectedSchema: (key: string) => void;
    isLoading: boolean;
}

export const DropDown = ({ schemas, selected, setSelectedSchema, isLoading }: DropDownProps) => {
    const { classes } = useStyles();
    const { setSidebarContent } = useSidebarContext();

    const handleChange = (key: { toString: () => string }) => {
        setSidebarContent(undefined);

        setSelectedSchema(key.toString());
        trackUserAction(UserAction.USER_TOGGLED_AFFINITIES_VIEW, {
            category: Categories.AFFINITY_SCHEMA,
        });
    };

    if (isLoading) {
        return <Skeleton variant="rectangle" width="250px" height="40px" />;
    }
    return (
        <div className={classes.DropdownContainer}>
            <Typography noWrap className={classes.viewHeader}>
                {VIEW}
            </Typography>
            <SelectMenu
                classes={{
                    button: classes.selectMenuButton,
                    labelContainer: classes.selectMenuLabel,
                }}
                selected={selected}
                onSelect={handleChange}
                options={schemas.map((schema) => ({ key: schema.name, label: schema.name }))}
            />
        </div>
    );
};
