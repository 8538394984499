export interface ProductLogoProps {
    productName: string;
    logoName: string;
    isDarkMode: boolean;
}

export const ProductLogo = ({ productName, logoName, isDarkMode }: ProductLogoProps) => {
    let src = `${logoName}.svg`;
    if (isDarkMode && !logoName.includes('White')) {
        src = `${logoName}White.svg`;
    }
    return <img src={src} title={productName} alt={productName} className={productName} />;
};
