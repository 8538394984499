import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import classNames from 'classnames';

import { useStyles } from './PageContent.styles';

export interface PageContentProps {
    breadcrumbSegments: BreadcrumbSegment[];
    breadcrumbsLoading?: boolean;
    pageTitle: string;
    pageTitleLoading?: boolean;
    titleActions?: JSX.Element;
    children: React.ReactNode;
    childrenLoading?: boolean;
    childrenLoadingContent?: JSX.Element;
    pageContentPadding?: boolean;
}

export const PageContent = ({
    breadcrumbSegments,
    breadcrumbsLoading = false,
    children,
    pageTitle,
    pageTitleLoading = false,
    titleActions,
    childrenLoading = false,
    childrenLoadingContent,
    pageContentPadding = true,
}: PageContentProps) => {
    const { classes } = useStyles();

    return (
        <>
            {breadcrumbsLoading ? (
                <Skeleton variant="rectangle" width="200px" height="14px" />
            ) : (
                <Breadcrumb segments={breadcrumbSegments} medium />
            )}

            <TitleRow title={pageTitle ?? ''} isLoading={pageTitleLoading} actions={titleActions} />
            <div
                className={classNames(classes.container, {
                    [classes.hoveringTable]: pageContentPadding,
                })}
            >
                {childrenLoading && childrenLoadingContent ? childrenLoadingContent : children}
            </div>
        </>
    );
};
