import { Skeleton } from '@local/web-design-system/dist/components/Skeleton/Skeleton';

import { useStyles } from './LoadingSkeleton.styles';

export const LoadingSkeleton = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.skeleton}>
            <Skeleton variant="rectangle" width="100%" />
            <Skeleton variant="rectangle" width="100%" />
            <Skeleton variant="rectangle" width="100%" />
        </div>
    );
};
