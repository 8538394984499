import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    cardList: {
        marginTop: theme.spacing(7),
        justifyContent: 'space-around',
        display: 'inline-grid',
    },
    header: {
        color: theme.palette.grey[800],
        fontSize: '31px',
        fontWeight: 'normal',
        textAlign: 'center',
        maxWidth: '1111px',
        alignSelf: 'flex-start',
        display: 'inline-block',
    },
    description: {
        justifyItems: 'center',
        color: '#39393C',
        fontSize: '25px',
        fontWeight: '100',
        maxWidth: '1111px',
        textAlign: 'center',
        display: 'inline-block',
    },
    cards: {
        margin: 'auto',
        paddingTop: '40px',
        paddingBottom: theme.spacing(10),
        width: 'fit-content',
        display: 'grid',
        marginTop: theme.spacing(2),
        justifyItems: 'center',
        gap: '20px',
        rowGap: theme.spacing(14),
        gridTemplateColumns: 'repeat(1, 1fr)',
        '@media (min-width: 800px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '@media (min-width: 1200px)': {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        '@media (min-width: 1800px)': {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
}));
