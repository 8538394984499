import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    icon: {
        fontSize: '18px',
        right: '27px',
        position: 'absolute',
        pointerEvents: 'none',
    },
    input: {
        borderRadius: 0,
        padding: '0px',
        height: '45px',
        backgroundColor: 'white',
    },
    searchBox: {
        width: '100%',
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: 120,
    },
    container: {
        overflow: 'auto',
        marginBottom: theme.spacing(2),
        height: 120,
    },
    formControl: {
        width: 195,
    },
}));
