import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { getConfig, ProvideConfig } from '@local/app-config';
import { ErrorBoundary } from '@local/error-logging';
import { MessagesProvider } from '@local/messages/dist/MessagesContext';
import { MetricsWrapper, trackError } from '@local/metrics';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { ConnectivityCheck } from '@local/web-design-system/dist/components/ConnectivityCheck';
import { theme } from '@local/web-design-system/dist/styles/theme';
import { ThemeProvider } from '@mui/material/styles';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { TssCacheProvider } from 'tss-react';

import { version } from '../package.json';
import { App } from './App';
import { store } from './store/store';
import {
    ERROR_BOUNDARY_DETAILS,
    ERROR_BOUNDARY_MESSAGE,
    NO_INTERNET_CONNECTION,
    VERSION,
} from './strings';

const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

const tssCache = createCache({
    key: 'tss',
});

const errorScreen = () => (
    <>
        <ConnectivityCheck offlineMessage={NO_INTERNET_CONNECTION} />
        <ErrorScreen msg={ERROR_BOUNDARY_MESSAGE} details={ERROR_BOUNDARY_DETAILS} />
    </>
);

let ldClient: LDClient | null = null;

function LaunchDarklyProviderWrapper({ children }: { children: JSX.Element }) {
    const [loaded, setLoaded] = useState(false);
    const config = getConfig();
    // Only initialise the client if the global client is null - ie it hasn't been loaded before.
    if (ldClient === null) {
        ldClient = initialize(config.launchDarklyClientID, { anonymous: true });
    }

    useEffect(() => {
        if (ldClient !== null) {
            ldClient
                .waitForInitialization()
                .catch((reason) => {
                    trackError(reason, 'Error initialising LD client, rendering defaults');
                })
                .finally(() => setLoaded(true));
        }
    }, []);

    if (loaded) {
        const LDProvider = withLDProvider({ clientSideID: config.launchDarklyClientID, ldClient })(
            () => children,
        );
        return <LDProvider />;
    }

    return null;
}

// We only send data to sentry, datadog and segment for release builds (NODE_ENV production), not dev builds.
const recordMetrics = process.env.NODE_ENV === 'production';

// Taken from https://sentry.io/settings/seequent-ltd/projects/evo/keys/
const sentryDsn =
    'https://c6b4174710314560a9848a215e5d45d0@o189631.ingest.sentry.io/4505027391979520';

const datadogConfig = {
    appId: '7b0cf089-4075-4d9a-a29f-d9b926261eaf',
    clientToken: 'pub95bfb030ec6fbfb4319385f4c5ce2119',
    service: 'evo',
    enableSessionReplays: true,
    version,
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <ProvideConfig>
            <IntlProvider locale="en" defaultLocale="en">
                <CacheProvider value={muiCache}>
                    <TssCacheProvider value={tssCache}>
                        <ThemeProvider theme={theme}>
                            <ErrorBoundary
                                errorComponent={errorScreen()}
                                sentryDSN={recordMetrics ? sentryDsn : undefined}
                                releaseVersion={VERSION}
                            >
                                <MetricsWrapper
                                    segmentWriteKey={
                                        recordMetrics ? getConfig()?.evo_segmentWriteKey : undefined
                                    }
                                    datadogConfig={recordMetrics ? datadogConfig : undefined}
                                >
                                    <Provider store={store}>
                                        <MessagesProvider>
                                            <BrowserRouter>
                                                <LaunchDarklyProviderWrapper>
                                                    <App />
                                                </LaunchDarklyProviderWrapper>
                                            </BrowserRouter>
                                        </MessagesProvider>
                                    </Provider>
                                </MetricsWrapper>
                            </ErrorBoundary>
                        </ThemeProvider>
                    </TssCacheProvider>
                </CacheProvider>
            </IntlProvider>
        </ProvideConfig>
    </StrictMode>,
);
