import { DateRange } from '@local/web-design-system/dist/components/DateRangeCalendar/types';

export const isSubString = (subString?: string, wholeString?: string): boolean => {
    if (subString === undefined || wholeString === undefined) {
        return false;
    }
    return wholeString.toLowerCase().includes(subString.toLowerCase());
};
export const dateRangeToString = (dateRange: DateRange) =>
    `${dateRange.startDate?.toLocaleDateString()} - ${dateRange.endDate?.toLocaleDateString()}`;
