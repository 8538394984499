import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    searchContainer: {
        padding: '12px',
        maxHeight: '100%',
        overflow: 'hidden',
    },
    controlContainer: {
        paddingTop: '16px',
        paddingRight: '16px',
        alignItems: 'flex-end',
    },
    metadataContainer: {
        paddingBottom: '16px',
        paddingRight: '16px',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    bottomContainer: {
        paddingBottom: '16px',
    },
}));
