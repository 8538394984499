import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid/Grid';
import Link from '@mui/material/Link';

import {
    CloudApplicationsIcon,
    ConnectedWorkflowsIcon,
    DeveloperToolsIcon,
} from '../../../assets/ConnectedWorkflowsIcon';
import { CONTACT_US, LEARN_MORE_LINK, OVERVIEW } from '../../../strings';
import { ENQUIRE_ID } from '../enquireForm/enquireform';
import { useStyles } from './Hero.styles';

const cardStrings = [
    {
        title: 'Flexible Cloud Applications',
        description:
            'Access a range of cloud applications, Powered by Seequent Evo. Enabling faster and better decision making.',
        target: '',
        learnMore: '#products-section',
    },
    {
        title: 'Connected Workflows',
        description:
            'An ecosystem of connected solutions with your data at the core –  making manual and time-consuming work-arounds a thing of the past.',
        target: '_blank',
        learnMore: 'https://www.seequent.com/seequent-evo-the-evolving-ecosystem-explained/',
    },
    {
        title: 'Accessible Developer Tools',
        description:
            'Interact with the ecosystem in a way which suits you, with tools to automate workflows and data access through programmatic interaction using available developer tools.',
        target: '_blank',
        learnMore: 'https://developer.seequent.com/',
    },
];

export const HeroCard = ({
    iconSrc,
    title,
    description,
    target,
    learnMore,
}: {
    iconSrc: any;
    title: string;
    description: string;
    target: string;
    learnMore: string;
}) => {
    const { classes } = useStyles();

    return (
        <div className={classes.card}>
            <h3>
                {iconSrc}
                <span>{title}</span>
            </h3>
            <p>{description}</p>
            <Link
                className={classes.learnMore}
                target={target}
                href={learnMore}
                color="inherit"
                rel="noopener"
            >
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: LEARN_MORE_LINK }} />
            </Link>
        </div>
    );
};

export const Hero = () => {
    const { classes } = useStyles();

    const handleClickScroll = () => {
        const element = document.getElementById(ENQUIRE_ID);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Grid container className={classes.hero}>
            <div className={classes.background}>
                {/* update preload link in apps/evo/index.html if this image changes */}
                <img src="Mesh-BK-Hero-x1.png" alt="" className={classes.backgroundImage} />
            </div>
            <Grid item className={classes.heroPrimary}>
                <em>Seequent Evo</em> is the connected
                <br />
                future of technology for the subsurface
            </Grid>
            <Grid item className={classes.heroSecondary}>
                A flexible, evolving ecosystem of cloud applications, integrations, and APIs, with
                your data at the core – enabling you to work seamlessly across your workflow and
                solve problems in the moment. Seequent Evo is for geoprofessionals, developers, and
                partners.
            </Grid>
            <Grid container className={classes.cardHeader} justifyContent="space-between">
                <p>{OVERVIEW}</p>
                <Button onClick={handleClickScroll} className={classes.contact}>
                    {CONTACT_US}
                </Button>
            </Grid>
            <Grid container direction="row" justifyContent="center" className={classes.cardList}>
                <HeroCard
                    iconSrc={<CloudApplicationsIcon />}
                    title={cardStrings[0].title}
                    description={cardStrings[0].description}
                    target={cardStrings[0].target}
                    learnMore={cardStrings[0].learnMore}
                />
                <HeroCard
                    iconSrc={<ConnectedWorkflowsIcon />}
                    title={cardStrings[1].title}
                    description={cardStrings[1].description}
                    target={cardStrings[1].target}
                    learnMore={cardStrings[1].learnMore}
                />
                <HeroCard
                    iconSrc={<DeveloperToolsIcon />}
                    title={cardStrings[2].title}
                    description={cardStrings[2].description}
                    target={cardStrings[2].target}
                    learnMore={cardStrings[2].learnMore}
                />
            </Grid>
        </Grid>
    );
};
