import { trackUserAction } from '@local/metrics';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid/Grid';
import { useState } from 'react';

import { ProductLogo } from 'src/pages/landingPage/productLogo/ProductLogo';

import { UserAction, Categories } from '../../../metrics.types';
import { LEARN_MORE_BUTTON, LOGIN, COMING_SOON } from '../../../strings';
import { EvoDialog } from '../dialogs/EvoDialog';
import { LearnMore } from '../dialogs/learnMore/LearnMore';
import { useStyles } from './Card.styles';
import { CardProps } from './Card.types';

export const Card = ({
    productName,
    logoName,
    loginLink,
    descriptionPrimary,
    descriptionSecondary,
    learnMore,
    cta,
    tags,
    isIntegration,
    status,
}: CardProps) => {
    const { classes } = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);

    const displayLoginButton = !(isIntegration || !loginLink);
    const closeDialog = () => {
        setDialogOpen(false);
    };

    return (
        <Grid className={classes.card}>
            <div className={classes.header}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    className={classes.bar}
                >
                    <Grid item className={classes.tags}>
                        {tags.join(' - ')}
                    </Grid>
                    {displayLoginButton ? (
                        <Grid item className={classes.login}>
                            <a
                                href={loginLink}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                    trackUserAction(UserAction.APP_LOGIN_CLICKED, {
                                        label: productName,
                                        category: Categories.EVO_LANDING_PAGE,
                                    });
                                }}
                            >
                                {LOGIN}
                            </a>
                        </Grid>
                    ) : null}
                </Grid>
                <div className={classes.logo}>
                    <ProductLogo productName={productName} logoName={logoName} isDarkMode />
                </div>
                <Grid item className={classes.status}>
                    {status !== 'active' && COMING_SOON}
                </Grid>
            </div>
            <div className={classes.body}>
                <div className={classes.descriptionPrimary}>{descriptionPrimary}</div>
                <div className={classes.descriptionSecondary}>{descriptionSecondary}</div>
            </div>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                className={classes.footer}
            >
                <Grid item>
                    <Button
                        variant="text"
                        className={classes.secondaryButton}
                        onClick={() => {
                            setDialogOpen(true);
                            trackUserAction(UserAction.APP_LEARN_MORE_CLICKED, {
                                label: productName,
                                category: Categories.EVO_LANDING_PAGE,
                            });
                        }}
                    >
                        {LEARN_MORE_BUTTON}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        className={classes.primaryButton}
                        href={cta.link}
                        target="_blank"
                        onClick={() => {
                            trackUserAction(
                                status === 'active'
                                    ? UserAction.APP_REQUEST_TRIAL_CLICKED
                                    : UserAction.APP_REGISTER_INTEREST_CLICKED,
                                {
                                    label: productName,
                                    category: Categories.EVO_LANDING_PAGE,
                                },
                            );
                        }}
                    >
                        {cta.label}
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={closeDialog}
                maxWidth={false}
                classes={{
                    root: classes.root,
                    paper: classes.paper,
                }}
            >
                <EvoDialog
                    onClose={closeDialog}
                    productLogo={
                        <ProductLogo
                            productName={productName}
                            logoName={logoName}
                            isDarkMode={false}
                        />
                    }
                    callToAction={learnMore.callToAction}
                    learnMore={
                        <LearnMore
                            onClose={closeDialog}
                            description={learnMore.description}
                            cta={cta}
                            features={learnMore.featuresList}
                            productName={productName}
                            status={status}
                        />
                    }
                />
            </Dialog>
        </Grid>
    );
};
