import { getConfig } from '@local/app-config';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/src/urls';
import { NavigateFunction } from 'react-router-dom';

import { ID_PARAM } from 'src/strings';
import { WEBVIZ_VIEWER } from 'src/urls';

const DRIVER_HOST_PREFIX = 'driver';
const DRIVER_DEV_HOST_PREFIX = 'varify-qa.driver.dev';

export function createNavigateToViewer(
    navigate: NavigateFunction,
    hubCode: string | undefined,
    workspaceUuid: string | undefined,
    id: string | undefined,
) {
    // Higher order function which returns a callable function to navigate to viewer for a given object id.
    return () => {
        navigate(
            `/${WORKSPACES_LIST_PAGE}/${hubCode}/${workspaceUuid}/${WEBVIZ_VIEWER}?${ID_PARAM}=${id}`,
        );
    };
}

export function createNavigateToDriver(
    hostname: string,
    orgUuid: string | undefined,
    hubCode: string | undefined,
    workspaceUuid: string | undefined,
    id: string | undefined,
) {
    return () => {
        // Higher order function which returns a callable function to navigate to driver for a given object id.
        window.open(
            `https://${buildDriverUrl(hostname)}/${orgUuid}/${WORKSPACES_LIST_PAGE}/${hubCode}/${workspaceUuid}?${ID_PARAM}=${id}`,
            '_blank',
        );
    };
}

export function buildDriverUrl(hostname: string) {
    const appConfig = getConfig();
    const hostParts = hostname.split('.');
    const driverHostName =
        appConfig?.environment === 'development' ? DRIVER_DEV_HOST_PREFIX : DRIVER_HOST_PREFIX;
    const baseUrl = driverHostName.concat(`.${hostParts.slice(1).join('.')}`);
    return baseUrl;
}
