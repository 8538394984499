import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        root: {
            border: `1px solid ${theme.palette.grey[300]}`,
            flexShrink: 0,
            '&:focus': {
                outline: 'none',
            },
        },
        rootDisabled: {
            opacity: 0.5,
            cursor: 'auto',
        },
        listItemControl: {
            zIndex: 2,
            backgroundColor: 'white',
            minHeight: theme.spacing(5),
        },
        itemSelected: {
            border: `1px solid ${theme.palette.primary.main} !important`,
        },
        filterApplies: {
            border: `1px dashed ${theme.palette.primary.main}`,
        },
        visibilityToggleButtonContainer: {
            height: '100%',
        },
        colorBarContainer: {
            height: '100%',
            width: theme.spacing(0.5),
            minWidth: theme.spacing(0.5),
            backgroundColor: theme.palette.grey[300],
            position: 'relative',
        },
        mainContainer: {
            height: '100%',
            color: theme.palette.grey[700],
            position: 'relative',
        },
        clickable: {
            cursor: 'pointer',
        },
        nameContainer: {
            paddingLeft: theme.spacing(),
            overflowWrap: 'break-word',
        },
        name: {
            fontSize: 12,
            wordBreak: 'break-word',
            paddingLeft: theme.spacing(),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            userSelect: 'none',
        },
        menuContainer: {
            width: theme.spacing(3),
        },
        paddedContainer: {
            padding: theme.spacing(0, 0.5),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
