import {
    FieldDefinition,
    ListCellComponentProps,
    ListItem,
} from '@local/web-design-system/dist/components/GenericListing';
import Typography from '@mui/material/Typography/Typography';

import { EMPTY_FOLDER_MESSAGE } from 'src/strings';

import { ListedObjectDisplay } from './utils';

const EmptyCell = () => <Typography variant="body2" />;

export const EmptyFolderCell = ({ item }: ListCellComponentProps) => (
    <div style={{ paddingLeft: `${(item.depth + 1) * 30}px` }}>{EMPTY_FOLDER_MESSAGE}</div>
);

const EmptyFolderRowDefinition: FieldDefinition[] = [
    {
        key: 'empty1',
        label: 'Name',
        component: EmptyFolderCell,
    } as FieldDefinition<string>,
    {
        key: 'empty2',
        label: '',
        component: EmptyCell,
    } as FieldDefinition<string>,
    {
        key: 'empty3',
        label: '',
        component: EmptyCell,
    } as FieldDefinition<string>,
    {
        key: 'empty4',
        label: '',
        component: EmptyCell,
    } as FieldDefinition<string>,
    {
        key: 'empty5',
        label: '',
        component: EmptyCell,
    } as FieldDefinition<string>,
];

export function EmptyFolderRow({ depth }: { depth: number }) {
    return <ListItem item={{ depth } as ListedObjectDisplay} fields={EmptyFolderRowDefinition} />;
}
