import { common } from '@local/web-design-system/dist/styles/common';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    divider: {
        width: '100%',
        margin: theme.spacing(1, 0),
    },
    loadingStateBody: {
        paddingTop: theme.spacing(2),
    },
    buttonsWrapper: {
        gap: theme.spacing(1),
    },
    productList: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    productCheckbox: {
        paddingTop: '3px',
        paddingBottom: '3px',
    },
    checkboxLabel: {
        userSelect: 'none',
    },
    checkboxLabelText: {
        display: 'contents',
    },
    mainContent: {
        alignContent: 'flex-start',
        margin: theme.spacing(4, 4, 0.5),
        position: 'sticky',
    },
    list: {
        ...common(theme).thinScrollBar,
        height: `calc(100vh - ${theme.spacing(36.5)})`,
        overflow: 'auto',
        '@media (max-width: 1199px)': {
            height: '550px',
        },
    },
    messageQueue: {
        position: 'relative',
        maxWidth: '100%',
        paddingLeft: 0,
    },
}));
