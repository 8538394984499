import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    text: {
        textTransform: 'uppercase',
        borderBottom: '1px solid rgb(226, 224, 224)',
        paddingBottom: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        color: theme.palette.grey[800],
        fontSize: theme.typography.h6.fontSize,
        lineHeight: theme.spacing(2),
    },
}));
