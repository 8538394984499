import { useEffect, useState } from 'react';

import { usePersistedState } from './usePersistedState';

/**
 * Provides a custom hook for the object list search parameter.
 * The search parameter is stored in the url as a query parameter.
 */
export function useObjectSearchParams() {
    const [search, setSearch] = usePersistedState('name');

    const [objectName, setObjectName] = useState<string[] | undefined>();

    useEffect(() => {
        setObjectName(search ? [`ilike:*${search}*`] : undefined);
    }, [search]);

    const handleSearch = (value: string) => setSearch(value === '' ? undefined : value);

    return {
        search,
        setSearch: handleSearch,
        objectName,
    };
}
