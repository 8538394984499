import { clearLoginError } from '@local/login/dist/store/sessionStorageHelpers/loginErrorHelper';
import { trackError } from '@local/metrics';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useEffect } from 'react';

import { LOGIN_TO_WORKSPACES } from '../../../strings';
import { useStyles } from './Header.styles';

interface HeaderProps {
    getSignInUrl: () => Promise<URL | undefined>;
}

export const Header = ({ getSignInUrl }: HeaderProps) => {
    const { classes } = useStyles();

    useEffect(() => {
        clearLoginError();
    }, []);

    const handleLogin = async () => {
        const url = await getSignInUrl();
        if (url) {
            window.location.href = url.href;
        } else {
            trackError('Failed to getSignInUrl');
            // Without config, so just reload page & hope for the best
            window.location.reload();
        }
    };

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                    <Link href="https://www.seequent.com/" target="_blank" rel="noopener">
                        <img
                            src="SeequentEvoWhite.svg"
                            alt="SeequentEvo"
                            className={classes.logo}
                        />
                    </Link>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center">
                    <Button
                        color="primary"
                        variant="outlined"
                        size="large"
                        type="button"
                        onClick={handleLogin}
                        className={classes.loginButton}
                        automation-id="login-form-sign-in"
                    >
                        {LOGIN_TO_WORKSPACES}
                    </Button>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
