import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            height: '100%',
            overflow: 'hidden',
            boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.12)',
            background: 'white',
            zIndex: 1,
        },
        splitContainer: {
            height: '100%',
        },
        gutter: {
            cursor: 'row-resize',
            background: theme.palette.grey[100],
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
        myScenePanelContainer: {
            height: '50%',
            background: theme.palette.grey[100],
            paddingBottom: theme.spacing(),
        },
        titleBox: {
            backgroundColor: 'white',
            borderBottom: 'none',
        },
        titleText: {
            fontSize: '12px',
            textTransform: 'uppercase',
            backgroundColor: theme.palette.grey[100],
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
    }) as const;

export const useStyles = makeStyles()(styles);
