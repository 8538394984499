import { DefaultObjectIcon } from '@local/web-design-system/dist/icons/Artifacts/DefaultObjectIcon';
import { DesignModelRenderedIcon } from '@local/web-design-system/dist/icons/Artifacts/DesignModelRenderedIcon';
import { DrillholeIcon } from '@local/web-design-system/dist/icons/Artifacts/DrillholeIcon';
import { GeologicalModelIcon } from '@local/web-design-system/dist/icons/Artifacts/GeologicalModelIcon';
import { Grid2DIcon } from '@local/web-design-system/dist/icons/Artifacts/Grid2DIcon';
import { LineationsIcon } from '@local/web-design-system/dist/icons/Artifacts/LineationsIcon';
import { LineSegmentIcon } from '@local/web-design-system/dist/icons/Artifacts/LineSegment';
import { LocationsIcon } from '@local/web-design-system/dist/icons/Artifacts/LocationsIcon';
import { MergedPointsetIcon } from '@local/web-design-system/dist/icons/Artifacts/MergedPointsetIcon';
import { MeshIcon } from '@local/web-design-system/dist/icons/Artifacts/MeshIcon';
import { TensorGridBlocktreeIcon } from '@local/web-design-system/dist/icons/Artifacts/TensorGridBlocktreeIcon';
import { TopographyIcon } from '@local/web-design-system/dist/icons/Artifacts/TopographyIcon';
import { TransformValuesIcon } from '@local/web-design-system/dist/icons/Artifacts/TransformValuesIcon';
import { VariogramIcon } from '@local/web-design-system/dist/icons/Artifacts/VariogramIcon';

import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';

export const SchemaIcon = (schema?: string) => {
    const iconsDictionary = {
        [Schemas.DesignGeometrySchema]: DesignModelRenderedIcon,
        [Schemas.DownholeCollectionSchema]: DrillholeIcon,
        [Schemas.DownholeIntervalsSchema]: DrillholeIcon,
        [Schemas.ExploratoryLocationSchema]: LocationsIcon,
        [Schemas.GeologicalModelMeshesSchema]: GeologicalModelIcon,
        [Schemas.GeophysicalRecords1DSchema]: GeologicalModelIcon,
        [Schemas.GlobalEllipsoidSchema]: TopographyIcon,
        [Schemas.IntervalDownholesSchema]: DrillholeIcon,
        [Schemas.LineSegmentsSchema]: LineSegmentIcon,
        [Schemas.LineationsDataPointsetSchema]: LineationsIcon,
        [Schemas.LocalEllipsoidsSchema]: TopographyIcon,
        [Schemas.NonParametricContinuousCumulativeDistributionSchema]: TransformValuesIcon,
        [Schemas.PlanarDataPointsetSchema]: MergedPointsetIcon,
        [Schemas.PointsetSchema]: MergedPointsetIcon,
        [Schemas.Regular2DGridSchema]: Grid2DIcon,
        [Schemas.Regular3DGridSchema]: Grid2DIcon,
        [Schemas.RegularMasked3DGridSchema]: Grid2DIcon,
        [Schemas.Tensor2DGridSchema]: TensorGridBlocktreeIcon,
        [Schemas.Tensor3DGridSchema]: TensorGridBlocktreeIcon,
        [Schemas.TriangleMeshSchema]: MeshIcon,
        [Schemas.UnstructuredGridSchema]: Grid2DIcon,
        [Schemas.UnstructuredHexGridSchema]: Grid2DIcon,
        [Schemas.UnstructuredQuadGridSchema]: Grid2DIcon,
        [Schemas.UnstructuredTetGridSchema]: Grid2DIcon,
        [Schemas.VariogramSchema]: VariogramIcon,
    };

    const gooseType = schema && extractSchema(schema);
    if (gooseType && gooseType in iconsDictionary) {
        return iconsDictionary[gooseType as keyof typeof iconsDictionary];
    }

    return DefaultObjectIcon;
};
