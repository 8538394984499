import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{
    maxWidth: string | undefined;
    width: string | undefined;
    maxHeight: string | undefined;
    maxButtonWidth: string | undefined;
    noPadding: boolean | undefined;
    variant: string | undefined;
}>()((theme: Theme, { width, maxWidth, maxHeight, maxButtonWidth, noPadding }) => ({
    button: {
        minWidth: '200px',
        justifyContent: 'space-between',
        background: theme.palette.grey[200],
        height: theme.spacing(5),
        width: '100%',
    },
    buttonOpen: {
        minWidth: '200px',
        justifyContent: 'space-between',
        background: theme.palette.grey[50],
        height: '39px',
        width: '100%',
    },
    icon: {
        fontSize: '15px',
    },
    buttonText: {
        fontSize: '15px',
        color: theme.palette.grey[900],
    },
    buttonTextOpen: {
        fontSize: '15px',
    },
    container: {
        maxWidth: maxButtonWidth || '200px',
    },
    containerOpen: {
        maxWidth: maxButtonWidth || '200px',
        boxShadow: '-5px 0px 5px -3px rgba(0,0,0,0.2),5px 0px 10px 1px rgba(0,0,0,0.14)',
    },
    bodyContainer: {
        backgroundColor: theme.palette.grey[50],
        width,
        maxWidth: maxWidth || '400px',
        maxHeight,
        minWidth: '300px',
        padding: noPadding ? 0 : theme.spacing(2),
    },
    footerButtonText: {
        fontSize: '13px',
    },
    footerContainer: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        display: 'flex',
        flexWrap: 'wrap',
    },
    footerButton: {
        maxHeight: theme.spacing(5),
    },
}));
