import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    IconGlow: {
        paddingRight: '16px',
        width: '30px',
        filter: 'drop-shadow(0px 0px 5px rgb(0 175 253 / 0.7))',
    },
}));

export const ConnectedWorkflowsIcon = () => {
    const { classes } = useStyles();
    return <img src="ConnectedWorkflowsIcon.svg" alt="" className={classes.IconGlow} />;
};

export const CloudApplicationsIcon = () => {
    const { classes } = useStyles();
    return <img src="CloudApplicationsIcon.svg" alt="" className={classes.IconGlow} />;
};

export const DeveloperToolsIcon = () => {
    const { classes } = useStyles();
    return <img src="DeveloperToolsIcon.svg" alt="" className={classes.IconGlow} />;
};
