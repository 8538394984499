import { MenuItemWithIcon } from '@local/web-design-system/dist/components/Menu/Menu';
import { OverflowMenu } from '@local/web-design-system/dist/components/OverflowMenu/OverflowMenu';
import { UserIcon } from '@local/web-design-system/dist/icons/User/UserIcon';
import MenuItem from '@mui/material/MenuItem';
import { useSearchParams } from 'react-router-dom';

import { usePersistedState } from 'src/hooks/usePersistedState';

export const AffinityFolderRowActions = ({ folderId }: { folderId: string }) => {
    const [, setSearchParams] = useSearchParams();
    const [, setFolderID] = usePersistedState('folderID');

    return (
        <OverflowMenu options={[]} size="medium">
            <MenuItem
                onClick={() => {
                    setFolderID(folderId);
                    setSearchParams({ propertiesSidebar: 'open' });
                }}
                automation-id="edit-users-workspace-overflow-menu-item"
            >
                <MenuItemWithIcon IconElement={UserIcon} label="Properties" />
            </MenuItem>
        </OverflowMenu>
    );
};
