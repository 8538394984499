import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid/Grid';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import EvoBadge from '../../../assets/EvoBadge';
import {
    FOOTER_SLOGAN_1,
    FOOTER_SLOGAN_2,
    FOOTER_CONTACT_US,
    FOOTER_SEEQUENT_URL,
    FOOTER_DEVELOPERS,
    FOOTER_SEEQUENT_COPYWRIGHT,
} from '../../../strings';
import { useStyles } from './Footer.styles';

export const Footer = () => {
    const { classes } = useStyles();

    return (
        <AppBar position="static" className={classes.footer}>
            <Toolbar>
                <Grid container justifyContent="space-between">
                    <Grid container item className={classes.gridRootWidth}>
                        <Grid item>
                            <EvoBadge className={classes.logo} />
                        </Grid>
                        <Grid item>
                            <Typography
                                className={classnames(classes.evoTextShared, classes.evoText1)}
                            >
                                {FOOTER_SLOGAN_1}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={classnames(classes.evoTextShared, classes.evoText2)}
                            >
                                {FOOTER_SLOGAN_2}
                            </Typography>
                        </Grid>
                        <div className={classnames(classes.verticalSpacer, classes.copywrightText)}>
                            &nbsp;
                        </div>
                        <Grid item className={classes.copywrightText}>
                            <Typography
                                className={classnames(classes.evoTextShared, classes.evoText1)}
                            >
                                {FOOTER_SEEQUENT_COPYWRIGHT}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container item className={classes.gridRootWidth}>
                        <Grid item>
                            <Link
                                className={classes.link}
                                href="https://developer.seequent.com/"
                                target="_blank"
                                color="inherit"
                                rel="noopener"
                            >
                                {FOOTER_DEVELOPERS}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                className={classnames(classes.link, classes.contactUs)}
                                href="https://www.seequent.com/contact-us/"
                                target="_blank"
                                color="inherit"
                                rel="noopener"
                            >
                                {FOOTER_CONTACT_US}
                            </Link>
                        </Grid>
                        <div className={classnames(classes.verticalSpacer, classes.seequentLink)}>
                            &nbsp;
                        </div>
                        <Grid item className={classes.seequentLink}>
                            <Link
                                className={classes.link}
                                href="https://www.seequent.com/"
                                target="_blank"
                                color="inherit"
                                rel="noopener"
                            >
                                {FOOTER_SEEQUENT_URL}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
