import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import { useStyles } from './Header.styles';

interface Props {
    text: string;
    className?: string;
    automationId?: string;
}

export const Header = ({ text, className, automationId }: Props) => {
    const { classes } = useStyles();

    return (
        <Typography
            variant="h4"
            gutterBottom
            className={classNames([classes.text, className])}
            automation-id={automationId}
        >
            {text}
        </Typography>
    );
};
