import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    content: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
    },
    text: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    tableDragAndDrop: {
        color: theme.palette.primary.dark,
        height: '100px',
        justifyContent: 'center',
    },
}));
