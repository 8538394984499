import { useDiscoverContext } from 'src/contexts/DiscoverContext';

import { ObjectIconMarker } from './map/clustering/ObjectIconMarker';
import { ObjectBounds } from './map/ObjectBounds';

const Z_INDEX_OFFSET = 100000;

interface ActiveObjectViewProps {
    ignoredObjects?: string[];
}

export function ActiveObjectView({ ignoredObjects = [] }: ActiveObjectViewProps) {
    const { activeObject } = useDiscoverContext();
    const ignore = ignoredObjects.includes(activeObject?.object_id || '');

    return (
        activeObject && (
            <>
                <ObjectBounds object={activeObject} />
                {!ignore && (
                    <ObjectIconMarker
                        active
                        orgObject={activeObject}
                        zIndexOffset={Z_INDEX_OFFSET}
                    />
                )}
            </>
        )
    );
}
