import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { ExternalAllEntityState, Maybe } from '@local/webviz/dist/types/xyz';
import { UID_SUFFIXES, buildDiscreteCategories } from '@local/webviz/dist/utilities';
import { ArrayClass, MappingClass, toSuffixUid } from '@local/webviz/dist/xyz';
import merge from 'lodash-es/merge';

import { AttributeNameColormapsMapType } from 'src/store/colormap/types';

export function generateColormapSnapshot(
    attributeNameColormapsMap: AttributeNameColormapsMapType,
    getEntityState: (entityId: string) => Maybe<ExternalAllEntityState>,
) {
    let snapshot = {};

    Object.keys(attributeNameColormapsMap).forEach((key) => {
        attributeNameColormapsMap[key].forEach((association) => {
            if (association.colormap && 'gradient_controls' in association.colormap) {
                const {
                    id,
                    colors,
                    attribute_controls: attributeControls,
                    gradient_controls: gradientControls,
                } = association.colormap;
                const mappingId = toSuffixUid(id, UID_SUFFIXES.MAPPING);
                const gradientId = toSuffixUid(id, UID_SUFFIXES.GRADIENT);
                const mappingEntity = getEntityState(mappingId);
                const gradientEntity = getEntityState(gradientId);
                if (!mappingEntity || !gradientEntity) {
                    const currentSnapshot = {
                        [mappingId]: generateEntity(MappingClass.Continuous, {
                            id: mappingId,
                            gradient: gradientId,
                            gradient_control_values: gradientControls,
                            data_control_values: attributeControls,
                            visibility: Array(gradientControls.length + 1).fill(true),
                        }),
                        [gradientId]: generateEntity(ArrayClass.Color, {
                            id: gradientId,
                            array: colors,
                        }),
                    };
                    snapshot = merge(snapshot, currentSnapshot);
                }
            } else if (association.colormap && 'end_points' in association.colormap) {
                const {
                    id,
                    colors,
                    end_inclusive: endInclusive,
                    end_points: endPoints,
                } = association.colormap;
                const mappingId = toSuffixUid(id, UID_SUFFIXES.MAPPING);
                const colorsId = toSuffixUid(id, UID_SUFFIXES.COLORS);
                const titlesId = toSuffixUid(id, UID_SUFFIXES.TITLES);

                const categories = buildDiscreteCategories(endInclusive, endPoints);

                const currentSnapshot = {
                    [mappingId]: generateEntity(MappingClass.Discrete, {
                        id: mappingId,
                        categories: [colorsId, titlesId],
                        end_inclusive: endInclusive,
                        end_values: endPoints,
                        visibility: Array(endPoints.length + 1).fill(true),
                    }),
                    [colorsId]: generateEntity(ArrayClass.Color, {
                        id: colorsId,
                        array: colors,
                    }),
                    [titlesId]: generateEntity(ArrayClass.String, {
                        id: titlesId,
                        array: categories,
                    }),
                };
                snapshot = merge(snapshot, currentSnapshot);
            }
        });
    });
    return snapshot;
}
