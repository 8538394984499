import { makeStyles } from 'tss-react/mui';

const LOADING_STATE_HEIGHT_OFFSET = 240;

export const useStyles = makeStyles()((theme) => ({
    fullHeight: {
        height: `calc(100vh - ${LOADING_STATE_HEIGHT_OFFSET}px)`,
    },
    button: {
        paddingBottom: theme.spacing(1),
    },
}));
