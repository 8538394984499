import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    actionText: {
        fontSize: theme.typography.button.fontSize,
        paddingLeft: theme.spacing(),
    },
    icons: {
        display: 'flex',
        justifyContent: 'space-evenly',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        alignContent: 'start',
    },
    iconButton: {
        border: 'none',
        padding: theme.spacing(1.5),
        backgroundColor: 'transparent',
        color: theme.palette.primary.main, // Matches `menuItem` from <Menu />
        cursor: 'pointer',
    },
}));
