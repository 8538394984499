import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    enquireBody: {
        backgroundColor: 'black',
        paddingBottom: theme.spacing(5),
    },
    enquireHeader: {
        marginTop: theme.spacing(10),
        width: '65%',
        color: '#E2E0E0',
        fontSize: '45px',
        fontWeight: 'lighter',
        marginBottom: theme.spacing(2),
    },
    enquireSubHeader: {
        color: '#E2E0E0',
        width: '65%',
        fontSize: '21px',
        fontWeight: 'lighter',
        lineHeight: '31px',
        marginBottom: theme.spacing(2),
    },
    enquireButton: {
        display: 'flex',
        justifyContent: 'end',
    },
    primaryButton: {
        backgroundColor: '#3EA0F3',
        border: '1px solid #4CA0E7',
        borderRadius: '4px !important',
        boxShadow: '0 2px 5px 0 rgba(57, 57, 60, 0.1), inset 0 -1px 0 0 rgba(0, 0, 0, 0.1)',
        width: '140px',
        '&:hover': {
            backgroundColor: '#3486c5',
        },
        whiteSpace: 'nowrap',
    },
    enquireLink: {
        color: '#3EA0F3',
    },
}));
