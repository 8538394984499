import { useEffect, useState } from 'react';

import { useSearchParamsContext } from '../contexts/SearchParamsContext';

/**
 * A useState() hook wrapper that also persists the value into the query string.
 */
export const usePersistedState = (key: string) => {
    const [searchParams, setSearchParams] = useSearchParamsContext();
    const [value, setValue] = useState<string | undefined>(searchParams.get(key) ?? undefined);

    // When the local state changes, update the search params
    useEffect(() => {
        const prev = searchParams.get(key) ?? undefined;
        if (value === undefined) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value);
        }
        // only treat the search params change as a page navigation if they've actually changed.
        // Otherwise, we flood the browser's history stack with the same page, making the back/forwards buttons appear broken.
        const navigateOpts = { replace: value === prev };
        setSearchParams(searchParams, navigateOpts);
    }, [value]);

    // When the search params change, update the local state
    useEffect(() => {
        const param = searchParams.get(key);
        if (param !== value) {
            setValue(param ?? undefined);
        }
    }, [searchParams.get(key)]);

    return [value, setValue] as const;
};
