import Typography from '@mui/material/Typography';

interface Props {
    text?: string | null;
    className?: string;
    automationId?: string;
}

export const PropertyText = ({ text, className, automationId }: Props) => (
    <Typography
        variant="body2"
        style={{ overflowWrap: 'anywhere' }}
        className={className}
        automation-id={automationId}
    >
        {text || ''}
    </Typography>
);
