import { common } from '@local/web-design-system/dist/styles/common';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    objectProperties: {
        display: 'flex',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        alignContent: 'center',
    },
    versionHistory: {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    versionHistoryContainer: {
        ...common(theme).thinScrollBar,
        display: 'flex',
        flexDirection: 'column',
    },
    versionHistoryList: {
        ...common(theme).thinScrollBar,
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    versionHistoryValue: {
        color: theme.palette.grey[500],
    },
    versionHistoryTitle: {
        fontSize: theme.typography.h5.fontSize,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    versionHistoryItem: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
    },
    uploadNewVersion: {
        ...fonts.button,
        display: 'flex',
        gap: theme.spacing(2),
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary.light,
        paddingLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    properties: {
        display: 'grid',
        gridTemplateColumns: '38% auto',
        rowGap: theme.spacing(0.5),
        paddingLeft: theme.spacing(4),
    },
    propertiesNameHeader: {
        color: '#4A4A4C',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    propertiesHeader: {
        textTransform: 'uppercase',
        color: '#4A4A4C',
        fontSize: '14px',
        fontWeight: '500',
    },
    version: {
        position: 'relative',
        '&:before': {
            backgroundColor: theme.palette.grey[300],
            content: '""',
            marginLeft: '-0.2em',
            position: 'absolute',
            top: '1.7em',
            left: '0.90em',
            width: '0.13em',
            height: '75%',
        },
        '&:last-of-type': {
            minHeight: theme.spacing(4),
            '&:before': {
                display: 'none',
            },
        },
    },
    capitalize: {
        textTransform: 'capitalize',
    },
}));
