import { LandingPageProps, LoginError } from '@local/login';
import { getLoginError } from '@local/login/dist/store/sessionStorageHelpers/loginErrorHelper';
import Grid from '@mui/material/Grid/Grid';

import { CardList } from './cardList/CardList';
import { Enquire } from './enquireForm/enquireform';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';
import { Hero } from './hero/Hero';
import { useStyles } from './LandingPage.styles';

const ErrorContainer = () => {
    const { classes } = useStyles();
    return (
        <Grid container className={classes.errorArea}>
            <LoginError />
        </Grid>
    );
};

export const LandingPage = ({ getSignInUrl }: LandingPageProps) => {
    const { classes } = useStyles();
    const showErrorBanner = getLoginError();

    return (
        <div className={classes.root}>
            {showErrorBanner && <ErrorContainer />}
            <Header getSignInUrl={getSignInUrl} />
            <div automation-id="landing-page-excluding-header">
                <Hero />
                <div id="products-section" className={classes.productSection}>
                    <div className={classes.bodyImage} />
                    <Grid container className={classes.contentArea}>
                        <CardList />
                    </Grid>
                </div>
                <Enquire />
                <Footer />
            </div>
        </div>
    );
};
