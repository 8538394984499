import { ListedObject } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { createContext } from 'react';

export type GooseList = {
    data: ListedObject[];
};

export const GooseListInitial: GooseList = {
    data: [],
};

export const GooseListContext = createContext<GooseList>(GooseListInitial);
