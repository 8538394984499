import { useLazyGetObjectByIdQuery } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

export function useQueue(maxConcurrent: number) {
    const [getObjectByIdTrigger] = useLazyGetObjectByIdQuery();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const loadObject = (objectId: string) =>
        getObjectByIdTrigger({
            objectId,
            orgId,
            workspaceId,
        }).unwrap();

    const queueRef = useRef<string[]>([]);
    const pendingRef = useRef<number>(0);
    const [timestamp, setTimestamp] = useState<number>(Date.now());

    function enqueue(objectToLoad: string) {
        queueRef.current.push(objectToLoad);
        setTimestamp(Date.now());
    }

    function dequeue() {
        if (pendingRef.current < maxConcurrent && queueRef.current.length) {
            const objectId = queueRef.current.shift();
            if (objectId) {
                pendingRef.current += 1;
                setTimestamp(Date.now());
                loadObject(objectId)
                    .then(() => {
                        if (pendingRef.current > 0) {
                            pendingRef.current -= 1;
                            setTimestamp(Date.now());
                        }
                    })
                    .catch((error) => {
                        trackError(`Error loading object ${objectId}:`, JSON.stringify(error));
                    });
            }
        }
    }

    function clearQueue() {
        queueRef.current = [];
        pendingRef.current = 0;
    }

    useEffect(() => {
        if (!queueRef.current.length) {
            return;
        }

        const batchSize = maxConcurrent - pendingRef.current;
        for (let i = 0; i < batchSize; i += 1) {
            dequeue();
        }
    }, [timestamp]);
    useEffect(
        () => () => {
            clearQueue();
        },
        [],
    );

    return { enqueue };
}
