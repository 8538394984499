import { checkIsAdmin } from '@local/login';
import { Skeleton } from '@local/web-design-system';
import { BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { Breadcrumb } from '@local/web-design-system/dist/components/Breadcrumb/Breadcrumb';
import { WORKSPACES_LIST_PAGE, WORKSPACES_OVERVIEW_PAGE } from '@local/workspaces/dist/urls';
import { fetchWorkSpaceData } from '@local/workspaces/src/apiClients/workspaceClientEndpoints';
import { useParams } from 'react-router-dom';

import {
    WEBVIZ_VIEWER_BREADCRUMB,
    workspaceListingWithLinkBreadcrumb,
} from 'src/utils/breadcrumbs';

export const ViewerBreadcrumb = () => {
    const params = useParams();
    const isAdmin = checkIsAdmin();
    const { data: workspace, isLoading } = fetchWorkSpaceData(params, isAdmin);

    const segments: BreadcrumbSegment[] = [
        ...workspaceListingWithLinkBreadcrumb(params),
        {
            name: workspace?.name ?? '',
            path: `../${WORKSPACES_LIST_PAGE}/${params.hubCode}/${params.workspaceUuid}/${WORKSPACES_OVERVIEW_PAGE}`,
        },
        { name: WEBVIZ_VIEWER_BREADCRUMB },
    ];
    if (isLoading) {
        return <Skeleton variant="rectangle" width="300px" height="14px" />;
    }

    return <Breadcrumb segments={segments} />;
};
