import { NoSlidesSvg } from '@local/svgs/dist/svg/NoSlidesSvg';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import classNames from 'classnames';

import { DRAG_AND_DROP } from 'src/strings';

import { useStyles } from './PanelDragAndDrop.styles';

interface Props {
    isHighlighted: boolean;
}

export const PanelDragAndDrop = ({ isHighlighted }: Props) => {
    const { classes } = useStyles();

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.container}
            automation-id="panel-drag-and-drop"
        >
            <div
                className={classNames(classes.graphic, [isHighlighted ? classes.highlight : null])}
            >
                <NoSlidesSvg />
            </div>
            <Typography variant="body2" className={classes.text}>
                {DRAG_AND_DROP}
            </Typography>
        </Grid>
    );
};
