import { makeStyles } from 'tss-react/mui';

const HERO_MAX_WIDTH = '1130px';

export const useStyles = makeStyles()((theme) => ({
    hero: {
        position: 'relative',
        marginBottom: '20px',
        color: 'black',
        width: '100%',
        textAlign: 'center',
        padding: `${theme.spacing(9)} ${theme.spacing(2)} 0`,
        '@media (min-width: 1400px)': {
            padding: `${theme.spacing(9)} 10% 0`,
        },
        '@media (min-width: 1800px)': {
            padding: `${theme.spacing(9)} 15% 0`,
        },
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        overflow: 'hidden',
        backgroundImage: 'linear-gradient(180deg, #223553 0%, #000000 100%)',
        height: '90%',
    },
    backgroundImage: {
        position: 'absolute',
        top: `-${theme.spacing(8)}`,
        left: 0,
    },
    heroPrimary: {
        width: '100%',
        color: '#E2E0E0',
        fontSize: '45px',
        fontWeight: 'lighter',
        marginBottom: theme.spacing(4),
        em: {
            fontWeight: 'normal',
            fontStyle: 'normal',
        },
        zIndex: 1,
    },
    heroSecondary: {
        width: '100%',
        color: '#E2E0E0',
        padding: '0 80px 0',
        fontSize: '25px',
        fontWeight: 'lighter',
        marginBottom: '100px',
        '@media (max-width: 600px)': {
            padding: 0,
            marginBottom: '50px',
        },
        zIndex: 1,
    },
    learnMore: {
        color: '#71BDFD',
        fontSize: '15px',
        fontWeight: 'lighter',
    },
    cardList: {
        columnGap: '20px',
        rowGap: '30px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        justifyItems: 'stretch',
        maxWidth: HERO_MAX_WIDTH,
        margin: 'auto',
        zIndex: 1,
    },
    cardHeader: {
        maxWidth: HERO_MAX_WIDTH,
        margin: 'auto',
        display: 'block',
        p: {
            display: 'block',
            float: 'left',
            paddingLeft: '20px',
            textAlign: 'left',
            color: theme.palette.grey[50],
            fontSize: '17px',
            margin: '0 0 20px 0',
        },
        zIndex: 1,
    },
    card: {
        backgroundColor: '#16202E',
        border: '1px solid #2F3645',
        borderRadius: '5px',
        boxShadow: 'inset 0 0 10px 0 rgba(0, 0, 0, 0.7)',
        textAlign: 'left',
        padding: '24px',
        flexGrow: '1',
        maxWidth: `312px`,

        '&:hover': {
            h3: {
                span: {
                    color: 'white',
                },
            },
        },

        h3: {
            margin: 0,
            height: '20px',
            padding: '10px 0px 10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            span: {
                display: 'inline-block',
                margin: 0,
                color: '#99A3AF',
                fontSize: '19px',
                fontWeight: '200',
            },
        },
        p: {
            color: theme.palette.grey[50],
            fontSize: '17px',
            fontWeight: '100',
        },
    },
    contact: {
        color: '#99A3AF',
        textAlign: 'right',
        fontSize: '17px',
        float: 'right',
        display: 'block',
        paddingRight: '20px',
        '&:hover': {
            color: 'white',
        },
    },
}));
