import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    dialog: {
        backgroundColor: 'white',
        borderRadius: '5px',
        maxWidth: '750px',
    },
    content: {
        padding: theme.spacing(4),
    },
    close: {
        position: 'absolute',
        backgroundColor: 'transparent',
        border: 'none',
        right: theme.spacing(2.4),
        top: theme.spacing(1.8),
        svg: {
            height: '50px',
            path: {
                color: 'white',
            },
        },
        cursor: 'pointer',
    },
    header: {
        width: '100%',
        height: '100%',
        backgroundColor: '#16202E',
        borderRadius: '5px 5px 0 0 ',
    },
    logo: {
        display: 'flex',
        img: {
            height: theme.spacing(8),
        },
        padding: `${theme.spacing(3)} ${theme.spacing(3)}  0${theme.spacing(3)}`,
    },
    callToAction: {
        color: theme.palette.grey[800],
        fontSize: '20px',
        marginBottom: theme.spacing(2),
    },
}));
