import Typography from '@mui/material/Typography';
import { LeafletMouseEvent, Util } from 'leaflet';
import { useState } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';

import { useStyles } from './DisplayCoordinates.styles';

const formatLatitude = (lat: number) => (lat > 0 ? `${lat}°N` : `${Math.abs(lat)}°S`);
const formatLongitude = (lng: number) => (lng > 0 ? `${lng}°E` : `${Math.abs(lng)}°W`);

export function DisplayCoordinates() {
    const map = useMap();
    const { classes } = useStyles();
    const [cursorCoords, setCursorCoords] = useState([0, 0]);

    useMapEvent('mousemove', (event: LeafletMouseEvent) => {
        if (map) {
            // Wrap where the user clicks to be within valid coords, in case they somehow click somewhere unexpected
            const { lat, lng } = map.wrapLatLng(event.latlng);
            setCursorCoords([Util.formatNum(lat, 10), Util.formatNum(lng, 10)]);
        }
    });

    return (
        <div className={classes.base}>
            <Typography className={classes.coordText}>{`${formatLatitude(
                cursorCoords[0],
            )}, ${formatLongitude(cursorCoords[1])}`}</Typography>
        </div>
    );
}
