export enum UploadStatus {
    Uploaded = 'uploaded',
    Uploading = 'uploading',
    Failed = 'failed',
}

export interface FileStatus {
    fileName: string;
    uploadStatus: UploadStatus;
    size: number;
    fileId: string;
    percentCompleted: number;
}
