import {
    OrgListedObject,
    useDeleteObjectsByIdMutation,
    useEnhancedUpdateObjectByIdMutation,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { AlertDialog } from '@local/web-design-system/dist/components/Dialog/AlertDialog';
import { ListItem } from '@local/web-design-system/dist/components/GenericListing';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { MoveToBinIcon } from '@local/web-design-system/dist/icons';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import { ReactNode, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { useSearchParamsContext } from 'src/contexts/SearchParamsContext';
import { usePersistedState } from 'src/hooks/usePersistedState';
import {
    RECYCLE_DIALOG_CANCEL,
    RECYCLE_DIALOG_CONFIRM,
    RECYCLE_DIALOG_CONTENT,
    RECYCLE_DIALOG_TITLE,
    RECYCLE_DIALOG_WARNING,
    RECYCLE_TOAST_FAILURE,
    RECYCLE_TOAST_SUCCESS,
    RESTORE_TOAST_FAILURE,
    RESTORE_TOAST_SUCCESS,
    UNDO,
    VIEW_RECYCLED_OBJECTS,
} from 'src/strings';
import { formatObjectName } from 'src/utils/objectUtils';

import { objectDefinition } from './FieldRowDefinitions';
import { ObjectActions } from './ObjectActions';
import { useStyles } from './ObjectRow.styles';
import { ListedObjectDisplay } from './utils';

type Actions = {
    key: string;
    action: ReactNode;
};

export type NotificationContent = {
    message: string;
    actions?: Actions[];
};

interface ObjectRowProps {
    object: ListedObjectDisplay | OrgListedObject;
    displayEmptyActions?: boolean;
}

export function ObjectRow({ object, displayEmptyActions = false }: ObjectRowProps) {
    const params = useParams();
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const [id, setID] = usePersistedState('id');
    const [recycleOpen, setRecycleOpen] = useState(false);
    const [recycleObject] = useDeleteObjectsByIdMutation();
    const [restoreObject] = useEnhancedUpdateObjectByIdMutation();
    const [searchParams, setSearchParams] = useSearchParamsContext();

    const isSelected = id === object?.object_id;

    const handleRestoreObject = async () => {
        const response = await restoreObject({
            orgId: getOrgUuidFromParams(params),
            workspaceId: getSelectedWorkspaceFromParams(params),
            objectId: object.object_id,
            deleted: false,
            geoscienceObject: null,
        });
        if (response.error) {
            addMessage({
                message: RESTORE_TOAST_FAILURE,
                type: NotificationType.ERROR,
            });
        } else {
            addMessage({
                message: RESTORE_TOAST_SUCCESS,
                type: NotificationType.SUCCESS,
            });
        }
    };

    const handleRowClick = () => {
        if (isSelected) {
            setID(undefined);
        } else {
            setID(object?.object_id);
        }
    };

    const onRecycle = async (confirm: boolean | null) => {
        if (confirm) {
            const response = await recycleObject({
                objectId: object.object_id,
                orgId: getOrgUuidFromParams(params),
                workspaceId: getSelectedWorkspaceFromParams(params),
            });

            if (response.error) {
                addMessage({
                    message: RECYCLE_TOAST_FAILURE,
                    type: NotificationType.ERROR,
                });
            } else {
                addMessage({
                    message: RECYCLE_TOAST_SUCCESS,
                    children: [
                        <Button
                            onClick={handleRestoreObject}
                            className={classes.recycledToastButtonStart}
                        >
                            {UNDO}
                        </Button>,
                        <Button
                            component={Link}
                            to={`/${getOrgUuidFromParams(params)}/recyclebin`}
                            className={classes.recycledToastButtonEnd}
                        >
                            {VIEW_RECYCLED_OBJECTS}
                        </Button>,
                    ],
                    type: NotificationType.SUCCESS,
                    inlineChildren: true,
                });
            }
        }
        setRecycleOpen(false);
        searchParams.delete('id');
        setSearchParams(searchParams);
    };

    return (
        <>
            <ListItem
                item={object}
                selected={isSelected}
                fields={objectDefinition}
                clickHandler={handleRowClick}
                displayEmptyActions={displayEmptyActions}
                actions={<ObjectActions object={object} onRecycle={() => setRecycleOpen(true)} />}
            />
            <AlertDialog
                open={recycleOpen}
                icon={<MoveToBinIcon />}
                action={onRecycle}
                cancelText={RECYCLE_DIALOG_CANCEL}
                confirmText={RECYCLE_DIALOG_CONFIRM}
                dialogTitle={RECYCLE_DIALOG_TITLE}
                dialogContent={
                    <div>
                        {`${RECYCLE_DIALOG_CONTENT} "${formatObjectName(object.name)}".`}
                        <br />
                        {RECYCLE_DIALOG_WARNING}
                    </div>
                }
            />
        </>
    );
}
