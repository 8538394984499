import { CheckIcon, ErrorIcon } from '@local/web-design-system/dist/icons';

import { UploadStatus } from 'src/types/files';

interface Props {
    status: UploadStatus;
}

export const UploadStatusIcon = ({ status }: Props) => {
    if (status === UploadStatus.Uploaded) {
        return <CheckIcon color="success" />;
    }
    if (status === UploadStatus.Failed) {
        return <ErrorIcon color="error" />;
    }
    return null;
};
