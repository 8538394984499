import { OrgSelectMenu } from '@local/login/dist/components/orgSelector/OrgSelectMenu/OrgSelectMenu';
import {
    getAvailableEvoInstances,
    getCurrentEvoInstance,
    setCurrentEvoInstance,
} from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import uniqBy from 'lodash-es/uniqBy';
import { useParams } from 'react-router-dom';

import { useStyles } from './HubSelector.styles';

export const HubSelector = () => {
    const { classes } = useStyles();
    const params = useParams();
    const evoInstances = getAvailableEvoInstances();
    const currentEvoInstance = getCurrentEvoInstance();
    const currentHubCode = params?.hubCode ?? currentEvoInstance?.hub?.code;
    const hubs = uniqBy(
        evoInstances
            .map(({ hub }) => hub)
            .sort((a, b) => (a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1)),
        (hub) => hub.display_name,
    );
    const options = hubs.map(({ display_name, code }) => ({
        label: display_name,
        key: code,
    }));

    function navigateToHub(hub: string) {
        // Nothing to do if the user has selected their current hub
        if (hub === currentHubCode) {
            return;
        }

        const evoInstance = evoInstances.find((predicate) => predicate.hub.code === hub);
        if (evoInstance) {
            setCurrentEvoInstance({ org: evoInstance.org, hub: evoInstance.hub });
            // Not using `navigate()` because we want to force the page to reload even if the URL is the same (different
            // hubs within the same org).
            window.location.replace(`/${evoInstance.org.id}/workspaces`);
        }
    }

    // Do not show the org selector if the user only has access to a single hub
    if (hubs.length === 1) {
        return null;
    }

    return (
        <div className={classes.hubSelector}>
            <OrgSelectMenu
                options={options}
                onSelected={(key: string) => navigateToHub(key)}
                selected={currentHubCode}
                variant="light"
                topSpacing={6}
                width={256}
            />
        </div>
    );
};
