import { VisibilityToggleButton } from '@local/web-design-system/dist/components/VisibilityToggleButton';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context';
import { createSnapshotToUpdateVisibility } from '@local/webviz/dist/context/snapshots';
import { ElementClass } from '@local/webviz/dist/xyz';
import { useState } from 'react';

import { useAppSelector } from '../../../../store/store';
import { selectionListScenePanel } from '../../../../store/visualization/selectors';
import { ObjectVisibilityControlProps } from './SceneListItemControl.types';

export function ObjectVisibilityControl({ viewId, disabled }: ObjectVisibilityControlProps) {
    const applyTrace = useTrace(`visibility-icon-button-${viewId}`);

    const { getEntityState, useXyzListener, setStateFromSnapshot, getState } = useBaseXyz();
    const entityState = getEntityState(viewId);
    const selectedObjectIds = useAppSelector(selectionListScenePanel);
    const [isVisible, setIsVisible] = useState(
        (entityState && 'visible' in entityState && entityState.visible) ?? false,
    );
    useXyzListener(viewId, 'visible', setIsVisible);

    const handleVisibilityToggle = () => {
        const snapshots = [];
        if (selectedObjectIds && selectedObjectIds.includes(viewId.split(':')[0])) {
            selectedObjectIds.forEach((id) => {
                Object.keys(getState())
                    .filter(
                        (filterKey) =>
                            filterKey.includes('-') && !filterKey.includes(ElementClass.Tileset3D),
                    )
                    .forEach((key) => {
                        if (key.includes(id)) {
                            const snapshot = createSnapshotToUpdateVisibility(!isVisible, key);
                            snapshots.push(snapshot);
                        }
                    });
            });
        } else {
            const snapshot = createSnapshotToUpdateVisibility(!isVisible, viewId);
            snapshots.push(snapshot);
        }
        const mergedSnapshot = Object.assign({}, ...snapshots);
        setStateFromSnapshot(mergedSnapshot, {});
    };

    return (
        <VisibilityToggleButton
            isVisible={isVisible}
            onToggle={handleVisibilityToggle}
            disabled={disabled}
            applyTrace={applyTrace}
        />
    );
}
