import { ObjectPropertiesEmpty } from '@local/svgs/dist/svg/ObjectPropertiesEmpty';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography';

import { Header } from 'src/components/sidebar/Header';
import { useStyles } from 'src/components/sidebar/Sidebar.styles';
import { NO_SELECTED_OBJECT, PROPERTIES } from 'src/strings';

export const Empty = () => {
    const { classes } = useStyles();

    return (
        <>
            <Header text={PROPERTIES} />
            <Grid container className={classes.emptyContainer}>
                <ObjectPropertiesEmpty />
                <Typography variant="body2" className={classes.emptyText}>
                    {NO_SELECTED_OBJECT}
                </Typography>
            </Grid>
        </>
    );
};
