import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    descriptionTitle: {
        color: '#3D3D3D',
        fontSize: '16px',
        marginBottom: theme.spacing(1),
    },
    description: {
        color: theme.palette.grey[800],
        fontSize: '16px',
        fontWeight: 'lighter',
        marginBottom: theme.spacing(2),
    },
    listContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    listItem: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        alignItems: 'center',
        '> span': {
            fontSize: '16px',
            fontWeight: 'lighter',
            paddingLeft: '5px',
            paddingBottom: '3px',
        },
    },
    tick: {
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '24px',
    },
    findOutMore: {
        color: '#3D3D3D',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: '18px',
        a: {
            color: '#0000EE',
            textDecoration: 'underline',
        },
    },
    images: {
        marginTop: theme.spacing(3),
    },
    image: {
        backgroundColor: '#D8D8D8',
        width: '48%',
    },
    footer: {
        marginTop: theme.spacing(4),
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        paddingTop: theme.spacing(3),
    },
    closeButton: {
        marginRight: theme.spacing(2),
        button: {
            width: '150px',
        },
    },
    requestDemoButton: {
        borderRadius: '4px !important',
        fontSize: '14px !important',
        lineHeight: '21px',
        backgroundColor: '#3EA0F3',
        border: '1px solid #4CA0E7',
        color: 'white',
        height: '42px',
        '&:hover': {
            borderColor: '#4CA0E7',
            backgroundColor: '#3486c5',
        },
    },
}));
