import { fonts } from '@local/web-design-system/dist/styles/fonts';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    tableHeader: {
        ...fonts.h2,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.main,
    },
    sideBar: {
        paddingLeft: theme.spacing(3),
    },
}));
