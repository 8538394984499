import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    base: {
        padding: '8px',
        color: 'white',
        display: 'flex',
        borderRadius: '8px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#1d1f23',
        boxShadow: '0px 8px 14px 0px #00000040',
    },
    coordText: {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
    },
}));
